.bien {
	/* ----------------------------------------------------- */
	/* MAP */
	/* ----------------------------------------------------- */
	&__map {
		position: relative;

		#map {
			height: 133vw !important;
			max-height: 750px;
		}

		&__listing {
			display: none;
		}

		.bien__grid__item {
			width: 100% !important;
		}
	}
}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* XXS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xxs) {

	

}


/* ----------------------------------------------------- */
/* XS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xs) {

	

}


/* ----------------------------------------------------- */
/* SM */
/* ----------------------------------------------------- */
@media (min-width: $screen-sm) {

	

}


/* ----------------------------------------------------- */
/* MD */
/* ----------------------------------------------------- */
@media (min-width: $screen-md) {

	.bien {
		&__map {
			padding-right: 0;
			padding-left: 1rem;
			transition: $cubic2;
			max-height: 75vh;

			body.maplisting-open & {
				padding-right: calc(100% / 3);
			}

			#map {
				height: 100% !important;
				max-height: 100%;
			}

			.bien__grid__item {
				&:hover {
					transform: scale(1);
				}
			}
		}
	}

}


/* ----------------------------------------------------- */
/* LG */
/* ----------------------------------------------------- */
@media (min-width: $screen-lg) {

	

}


/* ----------------------------------------------------- */
/* XL */
/* ----------------------------------------------------- */
@media (min-width: $screen-xl) {

	

}

