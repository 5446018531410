/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* MAIN */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
main[role="document"] {
	position: relative;
	z-index: 3;
	min-height: calc(100vh - 110px);
	padding-top: ptr(93px, $fz);
	padding-bottom: ptr(52px, $fz);


	/* ----------------------------------------------------- */
	/* SECTION ROUGE "ESPACE LOCATAIRE" */
	/* ----------------------------------------------------- */
	.espace_locataire {
		padding: 2rem 1rem;
		background-color: $col3;
		background-image: url( $imagePath + 'background/espace.svg');
		background-repeat: no-repeat;
		background-position: right bottom;
		color: $light;

		&__text {
			margin-bottom: ptr(12px, $fz);

			p {
				text-transform: uppercase;
				font-weight: 500;
				color: $light;

				strong {
					font-size: ptr(30px, $fz);
					vertical-align: middle;
					font-weight: bold;
				}
			}

		}
	}

	/* ----------------------------------------------------- */
	/* SECTION JAUNE "EN CAS D'URGENCE" */
	/* ----------------------------------------------------- */
	.urgence {
		background-color: $col6;
		padding: 2rem 1rem;

		&__text {
			@include flex(flex, row, nowrap, flex-start, flex-start);

			p {
				margin: 0;
				font-weight: bold;
				color: $col2;
				font-size: ptr(20px, $fz);

				i {
					font-size: ptr(32px, $fz);
					margin-right: ptr(13px, $fz);
				}

				.btn {
					margin-top: 0.5rem;

					i {
						font-size: 1rem;
					}
				}
			}
		}

		&__legend {
			margin-top: 1rem;
			p {
				font-size: ptr(11px, $fz);
				font-weight: 500;
				color: #3F3F3F;
			}
		}
	}
}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* XXS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xxs) {

	main[role="document"] {
		background-size: 25vw auto, 25vw auto, 25vw auto;
	}

}


/* ----------------------------------------------------- */
/* XS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xs) {
}


/* ----------------------------------------------------- */
/* SM */
/* ----------------------------------------------------- */
@media (min-width: $screen-sm) {

	main[role="document"] {
		padding-top: ptr(110px, $fz);

		.espace_locataire {
			&__text {
				p {
					font-size: 2rem;
				}
			}
		}

		.urgence {
			&__text {
				p {
					font-size: ptr(24px, $fz);

					i {
						font-size: ptr(64px, $fz);
					}
				}
			}

			&__legend {
				p {
					text-align: center;
				}
			}
		}
	}

}


/* ----------------------------------------------------- */
/* MD */
/* ----------------------------------------------------- */
@media (min-width: $screen-md) {

	main[role="document"] {
		padding-top: ptr(110px, $fz);
		padding-bottom: 0;
	}

}


/* ----------------------------------------------------- */
/* LG */
/* ----------------------------------------------------- */
@media (min-width: $screen-lg) {
}


/* ----------------------------------------------------- */
/* XL */
/* ----------------------------------------------------- */
@media (min-width: $screen-xl) {
}

