/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* SURCHARGE DES STYLES DE CAROUSEL */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* SLICK CAROUSEL */
/* ----------------------------------------------------- */
.slick-dotted.slick-slider {
	margin-bottom: 0;
	padding-bottom: 2rem;
}

/* Track en FLEX */
/* ----------------------------------------------------- */
.slick-track {
	@include flex(flex, row, nowrap, flex-start, flex-start);
}

/* BULLETS */
/* ----------------------------------------------------- */
.slick-dots {
	bottom: 0;
	padding: 0.25rem 0;
	z-index: 20;

	li {
		width: auto;
		height: auto;

		button {
			width: ptr(16px, $fz);
			height: ptr(16px, $fz);
			border-radius: ptr(16px, $fz);
			background-color: rgba($light, .2);
			padding: 0;
			box-shadow: 0 0 10px rgba($dark, .2);

			&:before {
				display: none;
			}
		}

		&.slick-active {
			button {
				background-color: $col1;
			}
		}
	}
}

/* FLECHES */
/* ----------------------------------------------------- */
.slider-arrows {
	@include centerEl();
	z-index: 4;
	color: $col3;
}

.slick-arrow {
	-webkit-appearance: none;
	@include flex(flex, row, nowrap, center, center);
	width: ptr(48px, $fz);
	height: ptr(48px, $fz);
	line-height: ptr(48px, $fz);

	background-repeat: no-repeat;
	background-size: contain;
	background-position: center center;

	font-size: 1rem;
	text-align: center;
	z-index: 10;
	text-decoration: none;

	color: $light;
	border-radius: 100rem;
	border: 1px solid $light;

	transition: $cubic2;

	&.slick-arrow-white {
		color: $light;
	}

	i {
		font-size: ptr(24px, $fz);
		line-height: ptr(40px, $fz);
	}

	&:before {
		display: none;
	}

	&:hover {
		text-decoration: none;
		color: $light;
		opacity: 1;
		background-color: $light;
		border-color: $light;
	}
}

.slick-prev {
	left: 0px;
}

.slick-next {
	right: 0px;
}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* XXS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xxs) {

	

}


/* ----------------------------------------------------- */
/* XS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xs) {

	

}


/* ----------------------------------------------------- */
/* SM */
/* ----------------------------------------------------- */
@media (min-width: $screen-sm) {

	.slider__home {
		.slick-arrow {
			color: rgba($light, .5);

			&:hover {
				color: rgba($light, 1);
			}
		}
	}

	.slider-arrows {
		top: calc(50% + #{$hdr_h} / 2);
	}

}


/* ----------------------------------------------------- */
/* MD */
/* ----------------------------------------------------- */
@media (min-width: $screen-md) {

	.slider-arrows {
		top: calc(50%);
	}

	.slick-arrow {
		
	}

}


/* ----------------------------------------------------- */
/* LG */
/* ----------------------------------------------------- */
@media (min-width: $screen-lg) {

	.slick-arrow {
		width: ptr(48px, $fz);
		height: ptr(48px, $fz);

		i {
			font-size: ptr(20px, $fz);
			line-height: ptr(48px, $fz);
		}
	}

	// .slick-prev {
	// 	left: ptr(-60px, $fz_lg);
	// }

	// .slick-next {
	// 	right: ptr(-60px, $fz_lg);
	// }
}


/* ----------------------------------------------------- */
/* XL */
/* ----------------------------------------------------- */
@media (min-width: $screen-xl) {

	

}

