 /* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* FOOTER */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* PARTIE "accompagnement" */
/* ----------------------------------------------------- */
.accompagnements__footer {
    display: none;
}


.site__footer {
    position: relative;
    color: $light;
    background-color: $ftr_bg;

    &__top {
        position: relative;
        padding: ptr(20px, $fz) 0;
    }

    &__bottom {
        padding: ptr(28px, $fz) 0;
    }

    /* ----------------------------------------------------- */
    /* ----------------------------------------------------- */
    /* "MODULES" */
    /* ----------------------------------------------------- */
    /* ----------------------------------------------------- */

    /* ----------------------------------------------------- */
    /* LOGO */
    /* ----------------------------------------------------- */
    &__logo {
        text-align: center;
        @include flex(flex, column, nowrap, center, center);

        img {
            width: auto;
            height: auto;
            margin-bottom: 1rem;
        }

        .h2 {
            color: $light;
            font-size: ptr(30px, $fz);
            text-transform: uppercase;
            font-weight: normal;
            margin: 0;
        }
    }


    /* ----------------------------------------------------- */
    /* Reseaux sociaux */
    /* ----------------------------------------------------- */
    &__networks {
        padding: 0;

        &__title {
            color: $light;
            text-transform: uppercase;
            text-align: center;
            font-size: ptr(16px, $fz);
            margin-bottom: ptr(10px, $fz);
            font-weight: 600;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            text-align: center;

            li {
                list-style: none;
                display: inline-block;
                vertical-align: top;
                font-weight: normal;
                margin: 0 1rem;

                a {
                    @include flex(flex, row, nowrap, center, center);
                    color: $col1;
                    border: none;
                    width: ptr(40px, $fz);
                    height: ptr(40px, $fz);
                    border-radius: 100rem;
                    background-color: $light;

                    i {
                        font-size: 1.25rem;
                    }

                }
            }
        }

    }

    /* ----------------------------------------------------- */
    /* CONTACT */
    /* ----------------------------------------------------- */
    &__contact {
        margin-bottom: ptr(52px, $fz);

        &__agence {
            &__tel {
                font-size: ptr(24px, $fz);
                font-weight: 600;
                text-align: center;
                margin: 1.25rem 0;
            }

            &__adresse {
                font-size: ptr(16px, $fz);
                text-transform: uppercase;
                text-align: center;
            }
        }
    }

    /* ----------------------------------------------------- */
    /* MENU */
    /* ----------------------------------------------------- */
    &__menu {
        &__nav {
            list-style: none;
            padding: 1rem;
            margin: 0;

            &__item {
                list-style: none;
                padding: 0;
                margin: 0;
                text-align: center;

                a {
                    display: inline-block;
                    color: $light;
                    text-transform: uppercase;
                    font-size: ptr(18px, $fz);
                    line-height: ptr(28px, $fz);
                    font-weight: 600;
                    margin: ptr(11px, $fz) 0;

                    i { display: none; }
                }
            }
        }
    }

    /* ----------------------------------------------------- */
    /* LIENS */
    /* ----------------------------------------------------- */
    &__links {
        margin: ptr(32px, $fz) 0;

        .btn {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
        }
    }

    /* ----------------------------------------------------- */
    /* Mentions / Koredge */
    /* ----------------------------------------------------- */
    &__mentions {
        ul {
            @include flex(flex, column, nowrap, center, center);
            padding: 0;
            margin: 0;
            list-style: none;

            li {
                display: block;
                vertical-align: middle;
                font-family: $ff1;
                color: $light;
                font-weight: normal;
                font-size: ptr(14px, $fz);
                line-height: ptr(28px, $fz);
                text-align: center;
                text-transform: uppercase;
                order: 1;

                a {
                    color: inherit;
                    display: block;

                    i {
                        display: block;
                        font-size: ptr(30px, $fz);
                    }

                    span {
                        text-transform: uppercase;
                    }
                }

                .sep { display: none; }

                &.koredge{
                    order: 3;
                    margin-top: 1rem;
                }

            }
        }
    }


}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* Scroll to Top */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
.scrolltotop {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: $cubic2;
    top: 0;
    right: .5rem;
    z-index: 2010;
    transform: translateY(-50%);

    &.visible {
        opacity: 1;
        visibility: visible;
    }

    button {
        -webkit-appearance: none;
        @include flex(flex, row, nowrap, center, center);
        width: ptr(48px, $fz);
        height: ptr(48px, $fz);
        cursor: pointer;
        padding: 0;
        color: $col1;
        background-color: $light;
        border-radius: 100rem;
        box-shadow: 0 0 8px rgba($dark, 0.4);

        i {
            font-size: ptr(24px, $fz);
        }
    }
}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* PROFILER CI */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
.profiler {
    position: fixed;
    z-index: 10000;
    bottom: 0;
    left: 0;
    background-color: $dark;
    color: $light;

    .open-profiler {
        color: $light;
    }
}

#codeigniter_profiler {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    overflow: auto;
    visibility: hidden;
    z-index: 9999;
    position: none;

    &.open {
        opacity: 1;
        visibility: visible;
    }
}

/* ---------------------------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------------------- */
/* RESPONSIVE                                                                                                 */
/* ---------------------------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------------------- */

/* ----------------------------------------------------- */
/* TABLE : #{$screen-xs} px */
/* ----------------------------------------------------- */
@media (min-width: $screen-xs) {

}

/* ----------------------------------------------------- */
/* TABLET : #{$screen-sm} px */
/* ----------------------------------------------------- */
@media (min-width: $screen-sm) {

    .accompagnements__footer {
        display: block;
        padding: 1.25rem 0;
        border-top: 1px solid $col8;
    }
    
    .accompagnement__footer {
        @include flex(flex, row, nowrap, flex-start, center);

        &__icon {
            width: ptr(48px, $fz);
            height: auto;

            .color {
                fill: $col2;
            }
        }

        &__titre {
            font-size: ptr(14px, $fz);
            text-transform: uppercase;
            font-weight: 600;
            color: $col1;
            padding-left: 1rem;
        }
    }

    .site__footer {
        &__logo {
            @include flex(flex, row, nowrap, flex-start, center);

            img {
                margin: 0;
                margin-right: ptr(12px, $fz);
            }

            .h2 {
                text-align: left;
                
            }
        }

        &__contact {
            margin: 0;
            padding-left: ptr(80px, $fz);

            &__agence {
                &__tel,
                &__adresse {
                    text-align: left;
                }
            }
        }

        &__bottom {
            border-top: 2px solid $light;
            padding-top: ptr(10px, $fz);
        }

        &__mentions {
            ul {
                @include flex(flex, row, nowrap, space-between, flex-start);

                li {
                    order: initial;
                    line-height: 1;

                    a { display: inline-block; }

                    &.koredge {
                        order: initial;
                        margin: 0;
                    }
                    .sep { display: inline; }
                }

            }
        }

    }

}

/* ----------------------------------------------------- */
/* TABLETP : #{$screen-md} px */
/* ----------------------------------------------------- */
@media (min-width: $screen-md) {

    .accompagnements__footer {
        .text-left {
            .accompagnement__footer {
                justify-content: flex-start;
            }
        }
        .text-center {
            .accompagnement__footer {
                justify-content: center;
            }
        }
        .text-right {
            .accompagnement__footer {
                justify-content: flex-end;
            }
        }
    }

    .accompagnement__footer {
        @include flex(flex, row, nowrap, flex-start, center);

        &__icon {
            width: ptr(52px, $fz);
        }

        &__titre {
            font-size: ptr(16px, $fz);
        }
    }

    .site__footer {

        &__top {
            padding: ptr(42px, $fz) 0 ptr(32px, $fz) 0;

            .row {
                align-items: flex-end;
            }
        }

        &__networks {
            margin: 0;

            &__title {
                // text-align: left;
            }

            ul {
                li {
                    a {
                        i {
                            transition: $cubic2;
                        }

                        &:hover {
                            color: $light;
                            background-color: $col2;
                        }
                    }
                }
            }
        }

        &__contact {
            &__agence {

                &__tel {
                    a {
                        &:hover {
                            color: $col2;
                        }
                    }
                }
                &__links {
                    a {
                        &:hover {
                            color: $light;
                            background-color: $col2;
                        }
                    }
                }
            }
        }

        &__menu {
            &__nav {
                display: block;
                padding: 0;

                &__item {
                    text-align: left;
                    a {
                        margin: 0;
                        margin-bottom: ptr(4px, $fz);

                        i {
                            display: inline-block;
                            // vertical-align: middle;
                            margin-right: 0.25rem;
                            width: ptr(18px, $fz);
                        }

                        &:hover {
                            color: $col2;
                        }
                    }
                }
            }
        }

        &__links {
            margin: 0;

            .btn {
                & + .btn {
                    margin-top: ptr(12px, $fz);
                }
            }
        }

        &__mentions {
            ul {
                li {
                    a {
                        &:hover {
                            color: $col2;
                        }
                    }
                }
            }
        }
    }

    .scrolltotop {
        button {
            width: ptr(52px, $fz);
            height: ptr(52px, $fz);
            overflow: hidden;

            i {
                font-size: ptr(26px, $fz);
            }

            &:hover {
                background-color: $col2;
                color: $light;

                i {
                    animation: fleche 1.4s $cubic2trans infinite forwards;
                }
            }
        }
    }


}


/* ----------------------------------------------------- */
/* DESKTOP : #{$screen-lg} px */
/* ----------------------------------------------------- */
@media (min-width: $screen-lg) {

    .accompagnements__footer {
        padding: ptr(28px, $fz) 0;
    }

    .accompagnement__footer {
        @include flex(flex, row, nowrap, flex-start, center);

        &__icon {
            width: ptr(56px, $fz);
        }

        &__titre {
            font-size: ptr(18px, $fz);
        }
    }

    .site__footer {

        &__menu {
        }

        &__links {
            .btn {
                padding: ptr(8px, $fz) 0;
            }
        }

    }

}

/* ----------------------------------------------------- */
/* DESKTOP : #{$screen-lg} px */
/* ----------------------------------------------------- */
@media (min-width: $screen-xl) {

    .site__footer {
        &__top,
        &__middle {
            padding: 4rem 0;
        }
    }

}