/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* SECTIONS */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
body.contenu-page {
	main[role="document"] {
		background-image: url($imagePath + 'fond-agences.png');
		background-repeat: no-repeat;
		background-position: bottom center;
		background-size: 100% auto;
		background-attachment: fixed;
	}
}


section {
	overflow: hidden;

	/* ----------------------------------------------------- */
	/* IMG TXT */
	/* ----------------------------------------------------- */
	&.img_txt {
		padding: 2rem 0;

		.image {
			padding: 0;
			box-shadow: 0 2px 12px rgba($dark, .2);
			border-radius: ptr(25px, $fz);
			margin-bottom: 1em;
			overflow: hidden;

			img {
				position: relative;
				z-index: 2;
				width: 100%;
				height: auto;
			}
		}

		.txt {
			padding-top: 1rem;
			padding-bottom: 1rem;
		}
	}

	/* ----------------------------------------------------- */
	/* TXT FULL */
	/* ----------------------------------------------------- */
	&.txt_full {
		padding: 2rem 0;

		.txt {
			p, ul li, ol li {
				color: $col7;
				line-height: 2;
				&:not(:last-child) {
					margin-bottom: 1rem;
				}
			}

			ul,
			ol {
				li {
					&:before {
						content: '\2022';
						color: $col1;
						display: inline-block;
						width: 1em;
						font-weight: bold;
						margin-left: -1em;
					}
				}
			}

			a {
				color: $col1-light;
				font-weight: bold;
				text-decoration: underline;
			}
		}
	}

	/* ----------------------------------------------------- */
	/* TXT IMG */
	/* ----------------------------------------------------- */
	&.txt_img {
		padding: 2rem 0;

		.txt {
			padding-top: 1rem;
			padding-bottom: 1rem;

			p, ul li, ol li {
				color: $col7;
				line-height: 2;
				&:not(:last-child) {
					margin-bottom: 1rem;
				}
			}

			ul,
			ol {
				li {
					&:before {
						content: '\2022';
						color: $col1;
						display: inline-block;
						width: 1em;
						font-weight: bold;
						margin-left: -1em;
					}
				}
			}

			a {
				color: $col1-light;
				font-weight: bold;
				text-decoration: underline;
			}
		}

		.image {
			padding-left: 1rem;
			padding-top: 1rem;
			padding-bottom: 1rem;
			position: relative;
			margin-bottom: 1rem;

			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 40%;
				height: 40%;
				border-radius: $bdrs;
				background-color: $col1;
				z-index: 1;
			}

			img {
				position: relative;
				z-index: 2;
				width: 100%;
				height: auto;
				border-radius: $bdrs;
				box-shadow: 0 2px 12px rgba($dark, .2);
			}
		}
	}

	/* ----------------------------------------------------- */
	/* IMG FULL  */
	/* ----------------------------------------------------- */
	&.img_full {
		padding: 1rem 0;

		.image {
			position: relative;

			img {
				position: relative;
				z-index: 2;
				width: 100%;
				height: auto;
				border-radius: $bdrs;
				// box-shadow: 0 2px 12px rgba($dark, .2);
			}
		}
	}
}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* XS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xs) {

	section {
		/* IMG TXT */
		/* ----------------------------------------------------- */
		&.img_txt,
		&.txt_img {
			padding: 2rem 0;
		}

		&.txt_full {
			padding: 2rem 0;
		}
	}
}


/* ----------------------------------------------------- */
/* SM */
/* ----------------------------------------------------- */
@media (min-width: $screen-sm) {

	section {
		/* IMG TXT */
		/* ----------------------------------------------------- */
		&.img_txt,
		&.txt_img {
			// padding: 3rem 0;

			.txt {
				padding: 0rem 1rem;
			}
		}

		&.txt_full {
			// padding: 3rem 0;
		}
	}
}


/* ----------------------------------------------------- */
/* MD */
/* ----------------------------------------------------- */
@media (min-width: $screen-md) {

	section {
		/* IMG TXT */
		/* ----------------------------------------------------- */
		// &.img_txt,
		// &.txt_img {
		// 	padding: 3rem 0;
		// }

		// &.txt_full {
		// 	padding: 3rem 0;
		// }

		// &.img_full {
		// 	padding: 5rem 0;
		// }
	}
}


/* ----------------------------------------------------- */
/* LG */
/* ----------------------------------------------------- */
@media (min-width: $screen-lg) {

	section {
		
	}

}


/* ----------------------------------------------------- */
/* XL */
/* ----------------------------------------------------- */
@media (min-width: $screen-xl) {

	

}

