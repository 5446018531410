.breadcrumbs {
    text-align: left;
    position: relative;
    padding: .5rem 0;
    margin: 0;
    color: $col1;
    font-size: ptr(12px, $fz);
    // border-top: 1px solid rgba($dark, .2);

    &__container {
        align-items: center;
        border-bottom: 1px solid rgba($dark, .2);
    }

    &__nav {
        text-align: right;
    }

    > span {
        display: inline-block;
        text-transform: none;
        color: inherit;
        font-family: $ff1;
        font-weight: bold;
        color: $col3;
    }

    a {
        display: inline-block;
        color: $col4;
        font-weight: normal;
    }
}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* MOBILE : #{-xxs} px */
/* ----------------------------------------------------- */
@media (min-width: $screen-xxs) {

}

/* ----------------------------------------------------- */
/* MOBILE : #{-xs} px */
/* ----------------------------------------------------- */
@media (min-width: $screen-xs) {

    .breadcrumbs {
        padding: .5rem 0;
    }

}

/* ----------------------------------------------------- */
/* TABLET : #{-sm} px */
/* ----------------------------------------------------- */
@media (min-width: $screen-sm) {
    .breadcrumbs {
        text-align: left;
    }
}

/* ----------------------------------------------------- */
/* TABLET PORTRAIT : #{-md} px */
/* ----------------------------------------------------- */
@media (min-width: $screen-md) {
    .breadcrumbs {
        font-size: ptr(14px, $fz_md);
        padding: 1rem 0;

        a {
            &:hover {
                color: $dark;
            }
        }

        &__nav {
            a {
                &:hover {
                    color: $col1;
                }
            }
        }
    }
}

/* ----------------------------------------------------- */
/* DESKTOP : #{-lg} px */
/* ----------------------------------------------------- */
@media (min-width: $screen-lg) {
}

/* ----------------------------------------------------- */
/* DESKTOP : #{-xl} px */
/* ----------------------------------------------------- */
@media (min-width: $screen-xl) {
}
