.contact {
	&__form {
		&__container {
			padding: 2rem 1rem;
			// border-radius: $bdrs;
			// background-color: $col3;

			.map {
				iframe {
					margin: 2rem 0;
				}
			}

			.form-submit {
				margin-top: 2rem;
				text-align: center;
			}

		}
	}
}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* XXS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xxs) {

	

}


/* ----------------------------------------------------- */
/* XS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xs) {

	.contact {
		&__form {
			&__container {
				.map {
					iframe {
						margin: 0;
					}
				}
			}
		}
	}

}


/* ----------------------------------------------------- */
/* SM */
/* ----------------------------------------------------- */
@media (min-width: $screen-sm) {

	.contact {
		&__form {
			&__container {
				padding: 3rem 2rem;

				.form-group.textarea {
					margin-top: 53px;
				}
			}
		}
	}

}


/* ----------------------------------------------------- */
/* MD */
/* ----------------------------------------------------- */
@media (min-width: $screen-md) {

	.contact {
		&__form {
			&__container {
				padding: 4rem 3rem;
			}
		}
	}

}


/* ----------------------------------------------------- */
/* LG */
/* ----------------------------------------------------- */
@media (min-width: $screen-lg) {

	

}


/* ----------------------------------------------------- */
/* XL */
/* ----------------------------------------------------- */
@media (min-width: $screen-xl) {

	

}

