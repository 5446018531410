.bien {
	/* ----------------------------------------------------- */
	/* LIST */
	/* ----------------------------------------------------- */
	&__list {
		padding: 0.5rem;

		&__item {
			position: relative;
			overflow: hidden;
			margin-bottom: ptr(24px, $fz);

			&__inner {
				@include flex(flex, row, nowrap, flex-start, stretch);
			}

			&__image {
				position: relative;
				border-radius: $bdrs 0 0 $bdrs;
				overflow: hidden;
				width: 25%;

				img {
					@include objfit();
				}
			}

			&__type {
				position: absolute;
				top: 0;
				left: 0;
				bottom: auto;
				right: auto;

				span {
					display: block;
					font-size: ptr(14px, $fz);
					padding: ptr(4px, $fz) ptr(12px, $fz);
					color: $light;
					background-color: $col1;
					text-transform: uppercase;
					border-radius: 0 $bdrs 0 0;
				}
			}

			&__exclu {
				position: absolute;
				bottom: 0rem;
				right: 0;
				width: 120px;
				height: 120px;
				background-image: url($imagePath + 'exclu.svg');
				background-size: cover;
			}

			&__content {
				position: relative;
				width: 75%;
				background-color: $col5;
				padding: ptr(12px, $fz);
				padding-top: 2rem;
				border-radius: 0 $bdrs $bdrs 0;
			}

			&__agence {
				font-size: ptr(12px, $fz);
				color: $col6;
				margin-bottom: .25rem;
			}

			&__designation {
				color: $dark;
				font-weight: 700;
				font-size: ptr(12px, $fz);
				margin-bottom: 0;
			}

			&__prix {
				position: absolute;
				top: 0;
				right: 0;
				color: $col1;
				padding: 0.25rem 0.75rem;
				font-size: ptr(14px, $fz);
				text-align: right;
				font-weight: bold;
			}

			&__details {
				@include flex(flex, row, nowrap, space-between, center);
				padding-top: ptr(12px, $fz);
				margin-top: ptr(12px, $fz);
				background-color: $col5;
				border-top: 1px solid $col-google;

				> div {
					i {
						font-size: ptr(12px, $fz);
						color: $col1;
						margin-right: ptr(4px, $fz);
						vertical-align: middle;
					}

					span {
						font-size: ptr(8px, $fz);
						color: $dark;
					}
				}
			}
		}
	}
}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* XXS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xxs) {

	

}


/* ----------------------------------------------------- */
/* XS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xs) {

	

}


/* ----------------------------------------------------- */
/* SM */
/* ----------------------------------------------------- */
@media (min-width: $screen-sm) {

	

}


/* ----------------------------------------------------- */
/* MD */
/* ----------------------------------------------------- */
@media (min-width: $screen-md) {

	.bien {
		&__list {
			&__results {
				margin: 0 0 0.5rem 0;

				p {
					color: $col6;
				}
			}

			&__item {
				transition: $cubic2;
				border-radius: $bdrs;

				&:hover {
					box-shadow: 0 0 12px rgba($dark, .2);
				}
			}
		}
	}

}


/* ----------------------------------------------------- */
/* LG */
/* ----------------------------------------------------- */
@media (min-width: $screen-lg) {

	

}


/* ----------------------------------------------------- */
/* XL */
/* ----------------------------------------------------- */
@media (min-width: $screen-xl) {

	

}

