.bien {

	/* ----------------------------------------------------- */
	/* RECHERCHE */
	/* ----------------------------------------------------- */
	&__search {
		margin: 2rem auto 0;

		&__types {
			@include flex(flex, row, nowrap, flex-start, stretch);
			margin-bottom: 1rem;
		}

		&__type {
			position: relative;
			width: 50%;

			&:first-child {
				padding-right: 0.5rem;
			}
			&:last-child {
				padding-left: 0.5rem;
			}

			input[type="radio"] {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;
				max-width: none;
				max-height: none;
				min-width: 0;
				min-height: 0;
				opacity: 0;
				margin: 0;
				padding: 0;

				&:checked ~ .nav-item {
					color: $light;
					background: $col1;
				}
			}

			.nav-item {
				display: block;
				width: 100%;
				color: $col1;
				background-color: $light;
				text-transform: uppercase;
				font-size: 1rem;
				font-weight: 600;
				// padding: 0rem 1.25rem;
				line-height: 2rem;
				text-align: center;
				border-radius: 100rem;
				box-shadow: 0 2px 10px rgba($dark, 0.2);
			}
		}

		&__form {
			background-color: $light;
			border-radius: 1rem 1rem 1rem 0rem;
			padding: 1rem;
			box-shadow: 0 2px 15px rgba($dark, 0.15);

			.form-group {
				.form-field {
					.form-field-icon.form-field-icon-left {
						color: $light;
					}

					&.form-submit {
						padding: 0rem 1rem;
					}
				}
			}

			button[type="submit"],
			input[type="submit"] {
				width: 100%;
				box-shadow: 0 2px 10px rgba($dark, .2);
			}
		}
	}

	/* ----------------------------------------------------- */
	/* RECHERCHE SUR LE LISTING */
	/* ----------------------------------------------------- */
	&__search__inline {

		// Btn de toggle
		&__toggle {

			button {
				display: block;
				width: 100%;
				padding: 1rem;
				background-color: $col1;
				color: $light;
				text-align: left;
				position: relative;
				text-transform: uppercase;
				font-weight: bold;

				i {
					display: block;
					position: absolute;
					top: 50%;
					right: 1rem;
					transform: translateY(-50%);
					font-size: ptr(12px, $fz);
					transition: $trans;
				}

				&.active {
					background-color: $col2;
					i {
						transform: translateY(-50%) rotate(90deg);
					}
				}
			}
		}

		.bien__search {
			display: none;

			input[type="submit"] {
				width: 100%;
			}

		}

	}

	/* ----------------------------------------------------- */
	/* SEARCH TOOLS */
	/* ----------------------------------------------------- */
	&__searchtools {
		@include flex(flex, column, nowrap, flex-start, stretch);
		margin: 1rem 0;

		&__left {
			order: 2;
		}

		&__right {
			order: 1;

			p {
				display: none;
			}
		}

		&__modes {
			position: relative;
			margin: 0;

			> input[type="checkbox"] {
				position: absolute;
				z-index: 100;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;
				opacity: 0;
				margin: 0;

				&:checked ~ .bien__searchtools__modes__inner:before {
					left: 50%;
				}
			}

			&__inner {
				position: relative;
				border-radius: 100rem;
				background-color: $light;
				box-shadow: inset 0 1px 8px rgba($col4, .5);
				overflow: hidden;
				@include flex(flex, row, nowrap, center, center);

				&:before {
					content: '';
					display: block;
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: auto;
					width: 50%;
					border-radius: 100rem;
					background-color: $col1;
					transition: $cubic2;
				}
			}

			&__mode {
				position: relative;
				z-index: 2;
				width: 50%;
				// border-radius: $bdrs;
				color: $col1;
				font-weight: bold;
				// background-color: $light;
				text-align: center;
				text-transform: uppercase;
				font-size: ptr(18px, $fz);
				line-height: ptr(40px, $fz);

				&.actif {
					// background-color: $col1;
					color: $light;
				}
			}
		}

		&__nbresults {
			color: $col6;
			font-size: ptr(13px, $fz);
			padding: 0.5rem 0;
			text-align: center;
		}

		&__count {
			color: $col2;
			font-weight: bold;
		}
	}
}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* XXS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xxs) {

}


/* ----------------------------------------------------- */
/* XS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xs) {

	.bien {
		&__search {
			&__type {
				max-width: ptr(175px, $fz);
				.nav-item {
					font-size: ptr(20px, $fz);
				}
			}
		}
	}

}


/* ----------------------------------------------------- */
/* SM */
/* ----------------------------------------------------- */
@media (min-width: $screen-sm) {

	.bien {
		&__search {
			padding-top: 0;

			&__type {
				max-width: ptr(225px, $fz);
				.nav-item {
					padding: ptr(12px, $fz) ptr(32px, $fz);;
					font-size: ptr(22px, $fz);
				}
			}

			&__form {
				border-radius: 100rem;
				position: relative;
				padding: 1rem 2rem 2rem 2rem;

				&__container {
					@include flex(flex, row, nowrap, space-between, center);

					.select_container {
						margin-bottom: 0;
					}

					.form-submit {
						position: absolute;
						width: auto;
						right: 0;
						top: 100%;
						margin: 0;
						transform: translateY(-50%);
					}

				}
			}
		}
	}

}


/* ----------------------------------------------------- */
/* MD */
/* ----------------------------------------------------- */
@media (min-width: $screen-md) {

	.bien {
		&__search {
			&__type {
				input[type="radio"] {
					cursor: pointer;
				}

				.nav-item { 
					transition: $cubic2;
				}

				&:hover {
					.nav-item {
						color: $light;
						background-color: $col2;
					}
				}
			}

			&__inline {
				&__toggle {
					display: none;
				}

				.bien__search {
					margin: 0;
					margin-bottom: 2rem;
					display: block !important;
					padding-bottom: 2rem;
				}

				&__form {
					@include flex(flex, row, nowrap, flex-start, center);

					.bien__search {
						&__types {
							@include flex(flex, column, nowrap, flex-start, stretch);
							margin: 0;
							margin-right: 0.5rem;
						}

						&__type {
							width: 100%;
							margin: 0;
							padding: 0;

							&:not(:last-child) {
								margin-bottom: 0.5rem;
							}

							.nav-item {
								padding: 0.25rem 2rem;
							}
						}

						&__form {
							background-color: transparent;
							box-shadow: none;

							&__container {
								.select_container,
								.form-group {
									padding: 0rem 0.25rem;
								}
							}
						}

					}
				}
			}
		}
		&__searchtools {
			@include flex(flex, row, nowrap, space-between, flex-end);
			margin: 1rem 0;
			padding: 0 0.5rem;

			&__left {
				order: 1;
				width: 50%;
			}

			&__nbresults {
				text-align: left;
			}

			&__right {
				order: 2;
				width: 50%;
				@include flex(flex, row, nowrap, flex-end, center);

				p {
					display: block;
				}
			}

			&__modes {
				margin: 0;
				margin-left: 1rem;

				input[type="checkbox"] {
					cursor: pointer;
				}

				&__mode {
					padding: 0rem 2rem;
					cursor: pointer;
				}

				&__inner {
					transition: $trans;
					background-color: $light;
				}

				&:hover {
					.bien__searchtools__modes__inner {
						background-color: rgba($col1, 0.1);
					}
				}
			}


			&__tri {
				.select_container {
					margin-bottom: 0;
				}
			}
		}
	}

}


/* ----------------------------------------------------- */
/* LG */
/* ----------------------------------------------------- */
@media (min-width: $screen-lg) {

	.bien {
		&__search {
			margin: 0;
			margin-top: 3rem;
			width: calc(100% / 1.2);
			max-width: ptr(1125px, $fz);

			&__types {
				margin-bottom: ptr(24px, $fz);
			}

			&__form {
				padding: ptr(24px, $fz) ptr(60px, $fz) ptr(24px, $fz) ptr(24px, $fz);
				
				&__container{
					@include flex(flex, row, nowrap, flex-start, center);

					.select_container {
						margin-bottom: 0;
						min-width: calc(20% - 1rem);

						&:not(:last-child) {
							margin-right: 1rem;
						}
					}

					.form-submit {
						top: 50%;
						left: 100%;
						bottom: auto;
						right: auto;
						transform: translate(-30%, -50%);
					}
				}

				// input[type="submit"] {
				// 	width: 100%;
				// 	padding: 0.75rem;
				// }
			}
		}

		&__search__inline {
			&__toggle {
				display: none;
			}

			.bien__search {
				max-width: none;
				width: 100%;

				&__container {
					display: block;
				}

				&__form {
					padding: 1.5rem 1.5rem 2rem 1.5rem;

					&__container {
						.form-submit {
							top: 100%;
							right: 0;
							left: auto;
							transform: translate(0, -50%);
						}
					}
				}
			}
		}
	}

}


/* ----------------------------------------------------- */
/* XL */
/* ----------------------------------------------------- */
@media (min-width: $screen-xl) {

	.bien {
		&__search__inline {
			&__toggle {
				display: none;
			}

			&__form {
				justify-content: space-between;
			}

			.bien__search {
				max-width: none;
				width: 100%;

				&__container {
					display: block;
				}

				&__form {
					padding: 0;
					width: 100%;

					&__container {
						.select_container {
							min-width: calc(100% / 6 - 1rem);
						}
						.form-submit {
							position: static;
							transform: none;
						}
					}
				}
			}
		}
	}

}

