.bien {

	/* ----------------------------------------------------- */
	/* GRID */
	/* ----------------------------------------------------- */
	&__grid {

		&__container {
			@include flex(flex, row, nowrap, flex-start, stretch);

			&.grid {
				.bien__grid {
					display: block;
					width: 100%;
				}

				.bien__map {
					width: 0%;
					display: none;
				}
			}

			&.map {
				.bien__grid {
					width: 0%;
					display: none;
				}

				.bien__map {
					display: block;
					width: 100%;
				}
			}

		}

		/* Vignette */
		/* ----------------------------------------------------- */
		&__item {
			width: 100%;
			position: relative;
			overflow: hidden;
			margin-bottom: ptr(24px, $fz);

			&__image {
				position: relative;
				border-radius: ptr(20px, $fz);
				overflow: hidden;

				img {
					width: 100%;
					height: auto;
				}

				&__overlay {
					display: none;
				}
			}

			&__type {
				position: absolute;
				top: ptr(6px, $fz);
				left: ptr(8px, $fz);

				span {
					display: block;
					padding: ptr(4px, $fz) ptr(30px, $fz);
					background-color: $col1;
					color: $light;
					font-weight: 600;
					letter-spacing: 1.14px;
					text-transform: uppercase;
					border-radius: 100rem 100rem 100rem 0;
				}
			}

			&__content {
				// background-color: $light;
				padding: ptr(12px, $fz) 0;
			}

			&__localisation {
				text-transform: uppercase;
				color: $dark;
				letter-spacing: 1.59px;
				font-size: ptr(14px, $fz);
				margin-bottom: ptr(4px, $fz);
			}

			&__designation {
				color: $dark;
				font-weight: bold;
				font-size: ptr(20px, $fz);
				text-transform: uppercase;
				// margin-bottom: ptr(14px, $fz);
			}

			&__prix {
				text-align: right;
				font-weight: bold;
				color: $col1;
				text-transform: uppercase;
				font-size: ptr(28px, $fz);
			}


			/* Big Vignette */
			/* ----------------------------------------------------- */
			&__big {
				position: relative;
				overflow: hidden;
				margin-bottom: ptr(24px, $fz);
				border-radius: $bdrs;

				&__image {
					position: relative;

					img {
						width: 100%;
						height: auto;
					}

					// ombre
					&:after {
						content: '';
						position: absolute;
						bottom: 0;
						left: 0;
						right: 0;
						top: 25%;
						background: rgb(0,0,0);
						background: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
					}
				}

				&__content {
					position: absolute;
					z-index: 2;
					left: 0;
					right: 0;
					bottom: 0;
					padding: ptr(16px, $fz) ptr(18px, $fz);
					color: $light;
					text-shadow: 0 2px 4px rgba($dark, .5);
				}

				&__agence {
					font-size: ptr(12px, $fz);
					font-weight: 500;
					margin-bottom: ptr(4px, $fz);
				}

				&__designation {
					font-size: ptr(20px, $fz);
					font-weight: 700;
					line-height: 1;
					margin-bottom: ptr(8px, $fz);
				}

				&__line {
					@include flex(flex, row, nowrap, space-between, center);
				}

				&__prix {
					font-size: ptr(15px, $fz);
				}

				&__details {
					font-size: ptr(11px, $fz);
				}
			}

			/* SMALL VIGNETTE */
			/* ----------------------------------------------------- */
			&__small {
				&__inner {
					@include flex(flex, row, nowrap, flex-start, stretch);
				}

				.bien__grid__item {
					&__image {
						width: 25%;
						border-radius: 10px 0 0 10px;

						img {
							@include objfit();
						}
					}

					&__content {
						position: relative;
						width: 75%;
						padding-top: 2rem;
						border-radius: 0 10px 10px 0;
					}

					&__type {
						top: 0;
						left: 0;
						bottom: auto;
						right: auto;

						span {
							font-size: ptr(14px, $fz);
							border-radius: 0 0 10px 0;
							padding: 0.25rem 0.75rem;
						}
					}

					&__prix {
						position: absolute;
						top: 0;
						right: 0;
						color: $col1;
						padding: 0.25rem 0.75rem;
						font-size: ptr(14px, $fz);
						text-align: right;
						font-weight: bold;
					}

					&__designation {
						font-size: ptr(12px, $fz);
						margin-bottom: 0;
					}

					&__details {
						> div {
							i {
								font-size: ptr(12px, $fz);
								margin-right: ptr(4px, $fz);
							}

							span {
								font-size: ptr(8px, $fz);
							}
						}
					}
				}
			}

			/* ITEM MARKER MAP */
			/* ----------------------------------------------------- */
			&__marker {
				position: relative;

				&__inner {
					padding: 1rem;
				}

				.bien__grid__item {
					&__type {
						bottom: 100%;
					}

					&__options {
						position: static;
						padding: 0.5rem 0 0 0;
					}
				}
			}
		}
	}

	/* ----------------------------------------------------- */
	/* LISTING */
	/* ----------------------------------------------------- */
	&__listing {
		position: relative;
		overflow: hidden;
	}


	/* ----------------------------------------------------- */
	/* MORE */
	/* ----------------------------------------------------- */
	&__more {
		margin: 2rem 0;
		text-align: center;
	}
}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* XXS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xxs) {

	

}


/* ----------------------------------------------------- */
/* XS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xs) {

	.bien {

		&__grid {

			&__container {
				&.grid {
					.bien__grid {
						@include flex(flex, row, wrap, flex-start, stretch);

						&__item {
							width: 50%;
							padding: 0rem .5rem;
						}
					}
				}
			}

			&__item {

				&__options {
					a {
						padding: 0.25rem 0.75rem;
					}
				}

				&__details {
					>div {
						i {
							font-size: ptr(14px, $fz);
						}
					}
				}

				&__big {
					grid-row: span 1;
					grid-column: span 2;
					margin-bottom: 0;

					&__agence {
						font-size: ptr(15px, $fz);
					}

					&__designation {
						font-size: ptr(24px, $fz);
					}

					&__prix {
						font-size: ptr(16px, $fz);
					}

					&__details {
						font-size: ptr(14px, $fz);
					}
				}
			}
		}
	}

}


/* ----------------------------------------------------- */
/* SM */
/* ----------------------------------------------------- */
@media (min-width: $screen-sm) {

	.bien {

		&__grid {
			&__item {

				&__designation {
					font-size: ptr(22px, $fz);
				}

				&__prix {
					// font-size: ptr(18px, $fz);
				}

				&__big {
					&__agence {
						font-size: ptr(16px, $fz);
					}

					&__designation {
						font-size: ptr(32px, $fz);
					}

					&__prix {
						font-size: ptr(24px, $fz);
					}

					&__details {
						font-size: ptr(20px, $fz);
					}
				}
			}
		}
	}

}


/* ----------------------------------------------------- */
/* MD */
/* ----------------------------------------------------- */
@media (min-width: $screen-md) {

	.bien {
		&__grid {
			&__container {
				&.grid {
					.bien__grid__item {
						width: calc(100% / 3);
					}
				}
				&.map {
					.bien__grid {
						max-height: 75vh;
						overflow: auto;
					}
				}
			}

			&__item {
				cursor: pointer;
				overflow: hidden;
				transition: $cubic2;

				&__image {
					position: relative;

					img {
						transition: transform 4s ease-out;
					}

					&__overlay {
						position: absolute;
						top: 0;
						right: 0;
						left: 0;
						bottom: 0;
						background-color: rgba($dark, .5);
						transition: $cubic2;
						transform: scale(0.95);
						opacity: 0;
						visibility: hidden;
						@include flex(flex, column, nowrap, center, center);
						color: $light;
						font-weight: bold;
						text-transform: uppercase;
						border-radius: ptr(20px, $fz);

						span {
							font-size: ptr(20px, $fz);
							&.plus {
								font-size: 3rem;
								line-height: 1;
							}
						}
					}
				}

				&:hover {
					.bien__grid__item__image {
						img {
							transform: scale(1.25);
						}
						&__overlay {
							opacity: 1;
							visibility: visible;
							transform: scale(1);
						}
					}
				}

				&__big {
					cursor: pointer;
					transition: $cubic2;

					&__image {
						&:after {
							transition: $cubic2;
						}
					}

					&__content {
						transition: $cubic2;
					}

					&:hover {
						transform: scale(1.025);
						box-shadow: 0 0 8px rgba($dark, .2);

						.bien__grid__item__big {
							&__image {
								&:after {
									top: 0;
								}
							}

							&__content {
								color: $col1-lighter;
							}
						}
					}
				}

				&__marker {
					.bien__grid__item__options {
						transform: none;
					}
				}

			}
		}
	}

	&__listing{
		&__container {
			overflow: hidden;
		}
	}

	.bien {
		&__grid {
			&__container {
				&.grid {
					.bien__grid__item {
						width: calc(100% / 4 - 1rem);
					}
				}
				&.map {
					.bien__grid,
					.bien__map {
						width: 50%;
						@include flex(flex, row, wrap, flex-start, stretch);
					}
					.bien__grid__item {
						width: calc(100% / 2);
						padding: 0.25rem;
					}
				}
			}
		}
	}

}


/* ----------------------------------------------------- */
/* LG */
/* ----------------------------------------------------- */
@media (min-width: $screen-lg) {

	.bien {
		&__grid {
			&__container {
				&.grid {
					.bien__grid__item {
						width: calc(100% / 4);
					}
				}
				&.map {
					.bien__grid,
					.bien__map {
						width: 50%;
					}
					.bien__grid__item {
						width: calc(100% / 2);
					}
				}
			}
			&__item {
				&__localisation {
					font-size: ptr(18px, $fz);
				}

				&__designation {
					font-size: ptr(22px, $fz);
				}

				&__prix {
					font-size: ptr(32px, $fz);
				}

				&__big {
					&__image {
						height: 100%;

						img {
							@include objfit();
						}
					}
				}
			}
		}
	}

}


/* ----------------------------------------------------- */
/* XL */
/* ----------------------------------------------------- */
@media (min-width: $screen-xl) {

	.bien {
		&__grid {

			&__item {
				&__details {
					>div {
						i {
							font-size: ptr(20px, $fz);
							margin-right: ptr(6px, $fz);
						}
						span {
							font-size: ptr(14px, $fz);
						}
					}
				}

				&__exclu {
					width: 160px;
					height: 160px;
				}
			}
		}
	}

}

