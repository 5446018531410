/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* HOMEPAGE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
section {
    overflow: hidden;

    /* ----------------------------------------------------- */
    /* RECHERCHE DE BIENS */
    /* ----------------------------------------------------- */
    &.recherche {
        padding: 0;
        padding-bottom: 2rem;

    	.recherche {
    		&__container {
                position: relative;
                border-radius: 50px 0 50px 0;
    			// background-image: url($imagePath + 'slide1.jpg');
    			background-size: cover;
    			overflow: hidden;
    			padding: ptr(50px, $fz) 2rem ptr(40px, $fz);
                box-shadow: 0 10px 50px rgba($dark, 0.25);

                .container {
                    padding: 0;
                }

                &__slider {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;

                    &.slick-slider {
                        .slick-track,
                        .slick-list {
                            height: 100%;
                        }

                        .slick-slide {
                            @include objfit();
                        }
                    }
                }
    		}

    		&__title {
    			h1 {
    				font-family: $ff1;
                    // font-size: ptr(34px, $fz);
                    font-weight: normal;
                    text-align: left;
                    
                    line-height: 1.2;
    				color: $light;
    				text-shadow: 0 2px 4px rgba($dark, 0.2);
    			}
    		}
		}
    }
 
    /* ----------------------------------------------------- */
    /* COUPS DE COEUR */
    /* ----------------------------------------------------- */
    &.coupsdecoeur {
        position: relative;
        padding: ptr(32px, $fz) 0;
        background-image: url($imagePath + 'fond-coups-de-coeur.jpg');
        background-size: cover;

        .section__title {
            text-align: left;
            margin-bottom: 1rem;
        }
        .section__subtitle {
            color: $col3;
            margin-bottom: 1rem;
        }

        .bien {
            &__container {
                overflow-y: hidden;
                overflow-x: auto;
            }

            &__content {
                // @include flex(flex, row, nowrap, flex-start, stretch);

                .row {
                    flex-wrap: nowrap;
                    flex-grow: 0;
                    flex-shrink: 0;
                }

                .bien__grid__item {
                    margin-bottom: 0;
                }
            }
        }
    }

    /* ----------------------------------------------------- */
    /* ACCOMPAGNEMENT */
    /* ----------------------------------------------------- */
    &.accompagnements {
        padding: 1rem 0;

        .accompagnement {
            text-align: center;
            border-radius: ptr(30px, $fz);
            background-color: $light;
            box-shadow: 0 2px 30px rgba($dark,.1);
            margin: ptr(24px, $fz) 0;
            padding: ptr(24px, $fz);

            &__icon {
                text-align: center;
                color: $col1;
                width: ptr(70px, $fz);
                height: auto;
                margin: 0 auto;

                .color {
                    fill: $col2;
                }
            }

            &__titre {
                margin: ptr(10px, $fz) 0;
                font-size: ptr(20px, $fz);
                font-weight: bold;
                text-transform: uppercase;
                text-align: center;
                line-height: 1;
            }

            &__texte {
                font-size: ptr(14px, $fz);
                color: $col3;
            }
        }
    }

    /* ----------------------------------------------------- */
    /* AGENCES */
    /* ----------------------------------------------------- */
    &.agences {
        padding: 1rem 0;
        background: url($imagePath + 'fond-agences.png') no-repeat bottom center;
        background-size: 100% auto;

        .agence {
            position: relative;
            border-radius: ptr(10px, $fz);
            padding: 2rem;
            overflow: hidden;
            margin-bottom: 1rem;
            color: $light;
            text-align: center;

            &:before {
                content: '';
                display: block;
                position: absolute;
                z-index: 1;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: rgba($col1, .75);
            }

            &.metabief { background-image: url($imagePath + 'agence-metabief.jpg'); }
            &.pontarlier { background-image: url($imagePath + 'agence-pontarlier.jpg'); }

            &__nom {
                position: relative;
                z-index: 2;
                font-size: ptr(20px, $fz);
                font-weight: 600;
                margin-bottom: 0.5rem;
            }
            &__tel {
                position: relative;
                z-index: 2;
                font-size: ptr(16px, $fz);
                margin-bottom: 1rem;
            }
            &__lien {
                position: relative;
                z-index: 2;
                
                .btn {
                    font-size: ptr(12px, $fz);
                }
            }

            &__adresse {
                position: relative;
                z-index: 2;

                p {
                    color: $light;
                    line-height: 2;
                    margin-bottom: 1rem;
                }
            }
        }
    }

    /* ----------------------------------------------------- */
    /* AVIS */
    /* ----------------------------------------------------- */
    &.avis-home {
        padding: 2rem 0;
        background-color: $col3;

        .bouton {
            order: 2;
            text-align: center;
        } 

        .titre {
            order: 1;

            h2 {
                margin-bottom: 1rem;
            }
        }

        .opinion {
            display: none;
        }

    }

    /* ----------------------------------------------------- */
    /* EQUIPE */
    /* ----------------------------------------------------- */
    &.equipe {
        padding: 2rem 0;

        .section__subtitle {
            text-align: center;
            margin-bottom: 1rem;
        }

    }

}


/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* XS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xs) {

	section {
        &.cartouches {
            .cartouche {
                height: 100%;
                margin-bottom: 0;
            }
        }

        &.agences {
            padding: 2rem 0;

            .agence {
                height: 100%;
                margin-bottom: 0;
            }
        }
        &.coupsdecoeur {
            .bien {
                &__container {
                    margin-top: 2rem;
                }
            }
        }
    }

}


/* ----------------------------------------------------- */
/* SM */
/* ----------------------------------------------------- */
@media (min-width: $screen-sm) {
	section {
        &.recherche {
            padding-top: 0rem;

            .recherche {
                &__container {
                    padding: ptr(100px, $fz) 1rem;
                }

                &__title {
                    h1 {
                        font-size: ptr(48px, $fz);
                    }
                    
                    h2 {
                    	font-size: ptr(21px, $fz);
                    }
                }
            }
        }

        &.coupsdecoeur {
            padding-top: ptr(72px, $fz);

            &:before {
                border-width: 72px;
            }
        }

        &.accompagnements {

            .accompagnements__container {
                border-radius: ptr(30px, $fz);
                background-color: $light;
                box-shadow: 0 2px 30px rgba($dark,.1);
                margin: ptr(24px, $fz) 0;
                padding: ptr(24px, $fz);
            }

            .accompagnement {
                background-color: transparent;
                box-shadow: none;
                padding: 0 1rem;
                margin: 0;
            }
        }

        &.agences {
            padding: 4rem 0;
        }

    }

}


/* ----------------------------------------------------- */
/* MD */
/* ----------------------------------------------------- */
@media (min-width: $screen-md) {

    section {
        &.recherche {
            .recherche {
	           &__title {
                    h1 {
                        font-size: ptr(60px, $fz);
                    }
                    h2 {
                        font-size: ptr(24px, $fz);
                    }
                }
            }
        }

        &.coupsdecoeur {
            .bien {
                &__content {
                    .row {
                        flex-grow: initial;
                        flex-shrink: initial;
                        flex-wrap: wrap;
                    }
                }
            }
            .bien__grid__item {
                &:hover {
                    transform: none;
                }
            }
        }


        &.accompagnements {
            .accompagnement {
                padding: 0rem 2rem;

                &__titre {
                    margin: ptr(24px, $fz) 0 1rem;
                }
            }
        }

    }

}


/* ----------------------------------------------------- */
/* LG */
/* ----------------------------------------------------- */
@media (min-width: $screen-lg) {

	section {
        &.recherche {
            .recherche {
                &__container {
                    padding: 3rem 1rem;
                }

                &__title {
                    width: 60%;
                }
            }
        }

        &.accompagnements {
            .accompagnements__container {
                padding: ptr(60px, $fz) 0;
            }

            .accompagnement {
                padding: 0rem 3rem;
            }
        }

    }

}


/* ----------------------------------------------------- */
/* XL */
/* ----------------------------------------------------- */
@media (min-width: $screen-xl) {

	section {
        &.recherche {
            .recherche {
               &__title {
                    h1 {
                        font-size: 5rem;
                    }
                    h2 {
                        font-size: ptr(28px, $fz);
                    }
                }
            }
        }

        &.cartouches {
            padding: 4rem 0;

            .cartouche {
                &__content {
                    padding-left: 1.25rem;
                }
            }
        }

        &.accompagnements {
            padding: 0 0 3rem 0;

            h2 {
                margin-bottom: 2rem;
            }

            .accompagnement {
                &__icon {
                    margin-bottom: 1.25rem;
                }

                &__titre {
                    margin-bottom: 1.25rem;
                }
            }
        }

        &.agences {
            .agence {
                padding: 4rem;
            }
        }
    }

}

