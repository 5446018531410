/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* POPUP PROFILE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
.popup {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    pointer-events: none;
    transition: $trans;
    transition-delay: 200ms;

    &__overlay {
        opacity: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($dark, 0.5);
    }

    &__content {
        @include centerEl();
        transform: translate(-50%, -50%) scale(.9);

        transition: $cubic2;
        transition-delay: 0ms;
        opacity: 0;
        visibility: hidden;

        min-width: 96%;
        max-width: 96%;
        max-height: 96%;
        background-color: $light;
        box-shadow: 0 0 3px 6px rgba($dark, .2);
        border-radius: $bdrs;

        overflow-x: hidden;
        overflow-y: auto;

        &__text {
            p {
                font-size: ptr(14px, $fz);
                text-align: justify;
            }
        }

    }

    &__inner {
        padding: 1rem;
    }

    &__close {
        position: absolute;
        top: .5rem;
        right: .5rem;
        padding: 0rem;
        cursor: pointer;
        background-color: $light;
        border-radius: 100rem;
        z-index: 20;
        // transform: translate(50%, -50%);

        button {
            color: $col3;
            padding: 0rem;
            width: 2rem;
            height: 2rem;
        }
    }

    &.open {
        opacity: 1;
        visibility: visible;
        pointer-events: initial;
        z-index: 29998;
        transition-delay: 0ms;

        .popup__overlay {
            opacity: 1;
        }

        .popup__content {
            opacity: 1;
            visibility: visible;
            transition-delay: 200ms;
            transform: translate(-50%, -50%) scale(1);
        }
    }

    &__title {
        padding: .5rem;
        background-color: $col3;
        color: $light;
        font-size: ptr(14px, $fz);
        font-weight: bold;
        padding-right: 2rem;
        text-align: center;
        text-transform: uppercase;
    }
}
/*
#overlay {
    position: fixed;
    overflow-y: scroll;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, .7);
    z-index: 2;
}

#overlay .close_overlay {
    cursor: pointer;
    position: absolute;
    top: 9px;
    right: 23%;
    z-index: 3;
}

#popUp_alerte {
    position: absolute;
    top: 51%;
    left: 50%;
    font-size: 50px;
    background-color: #EFECEC;
    width: 50%;
    color: #504E4E;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}
*/
.tab {
    background-color: white;
    display: flex;
    box-shadow: 10px 8px 17px -10px rgba(87, 87, 87, 0.38);
    flex-direction: row;
    margin-bottom: 1rem;

    .tablinks {
        display: flex;
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: bold;
        color: $col1;
        padding: 0.5rem 0;
        width: 100%;
        border: none;
        outline: none;
        cursor: pointer;
        transition: 0.3s;
        justify-content: center;
        position: relative;

        p {
            color: inherit;
        }

        &:hover {
            color: $col2;
        }

        &.active {
            color: $light;
            background-color: $col1;
        }
    }
}

.arrow_popUp {
    position: absolute;
    top: 5%;
    left: 23%;
}

.arrow_popUp_loc {
    position: absolute;
    top: 100%;
    left: 0%;
}

.tabcontent {
    padding: 0rem;
    border-left: none;
    display: none;

    &.open {
        display: block;
    }

    label {
        font-weight: bold;
    }

    .form-group {
        .form-field {
            &.form-submit {
                text-align: center;
                margin-top: 1rem;
            }

            &.form-range {
                p {
                    text-align: center;
                    font-size: 2rem;
                    color: $col2;
                    font-weight: bold;

                    span {
                        color: $col1;
                    }
                }
            }
        }
    }

    input[type="range"] {
        width: 100%;
    }

    label {
        &.check_label {
            font-weight: normal;
        }
    }
}



/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* XXS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xxs) {

    

}


/* ----------------------------------------------------- */
/* XS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xs) {

    .popup {
        &.estimation {
            .popup {
                &__content {
                    background: url($imagePath + 'popup-background.png') no-repeat bottom right $light;
                    background-size: contain;
                    padding-right: 16.7%;
                }
            }
        }
    }

}


/* ----------------------------------------------------- */
/* SM */
/* ----------------------------------------------------- */
@media (min-width: $screen-sm) {

    .popup {
        &__content {
            min-width: ptr(500px, $fz);
        }

        &__inner {
            padding: 1rem;
        }

        &__title {
            padding: 1rem;
            font-size: 1rem;
            position: relative;
        }

        &__text {
            padding: 1rem;
            font-size: 1rem;
            position: relative;
        }

        // &__close {
        //     position: absolute;
        //     top: 50%;
        //     right: .5rem;
        //     transform: translateY(-50%);
        // }

        &.estimation {
            .popup {
                &__inner {
                    padding: 3rem 0rem 1rem 2rem;
                }
            }
        }
    }

}


/* ----------------------------------------------------- */
/* MD */
/* ----------------------------------------------------- */
@media (min-width: $screen-md) {

    .popup {
        &__close {
            button {
                &:hover {
                    color: $col1;
                }
            }
        }

        &.estimation {
            .popup {
                &__close {
                    z-index: 99;
                    button {
                        cursor: pointer;
                        &:hover {
                            color: $col1;
                        }
                    }
                }

                &__inner {
                    padding: 5rem 0rem 1rem 3rem;

                    &__text {
                        margin-bottom: 3rem;
                    }
                }

                &__content {
                    min-width: ptr(750px, $fz);
                    max-width: ptr(875px, $fz);
                    padding-right: 20%;

                    .h2 {
                        font-size: ptr(32px, $fz);
                    }

                    .h3 {
                        font-size: ptr(24px, $fz);
                    }
                }

                &__footer {
                    p {
                        font-size: ptr(14px, $fz);;
                    }
                }
            }
        }
    }

}


/* ----------------------------------------------------- */
/* LG */
/* ----------------------------------------------------- */
@media (min-width: $screen-lg) {

    .tab .tablinks {
        padding: 1rem;
    }

}


/* ----------------------------------------------------- */
/* XL */
/* ----------------------------------------------------- */
@media (min-width: $screen-xl) {

    .popup {
        &.estimation {
            .popup {
                &__content {
                    padding-right: 16.7%;
                }
            }
        }
    }

}



