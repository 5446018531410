/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* FLASHDATA MESSAGES ADMIN */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
.alert_message,
.admin_message {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgb(0,0,0);
	background: -moz-linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.9) 50%);
	background: -webkit-linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.9) 50%);
	background: linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.9) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
	z-index: 2001;
	@include flex(flex, row, nowrap, center, center);

	> p,
	> div {
		padding: 2rem;
		border-radius: ptr(20px, $fz);
	}

	&.error p,
	&.error div {
		background-color: $danger;
		color: $light;
	}

	&.success p,
	&.success div {
		background-color: $success;
		color: $light;
	}

	&.warning p,
	&.warning div {
		background-color: $warning;
		color: $light;
	}

}