@media print {
	header,
	footer {
		display: none;
	}

	.page__breadcrumbs,
	.only-members, 
	.btn.print,
	.resultats__documents,
	.agenda__single__next,
	.agenda__single__prev,
	.scrolltotop
	{
		display: none;
	}
}