/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* PAGE DETAIL */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

.bien {
	&__detail {

		&__buttons {
			margin-bottom: 1rem;
		}

		.main {
			order: 2;
		}

		.aside {
			order: 1;
		}

		&__slider {
			margin-bottom: 1rem;
			&__container {
				position: relative;
			}

			img {
				width: 100%;
				height: auto;
			}

			&__arrows {
				position: absolute;
				bottom: 0;
				right: 0;
				@include flex(flex, row, nowrap, flex-end, center);
				padding: 0.25rem;

				.slick-arrow {
					position: static;
					padding: 0rem;
					border-radius: 0rem;
					border: none;
					background: none;
					transform: none;
					margin: 0rem 0.5rem;
					opacity: 0.5;
					width: auto;
					height: auto;

					i {
						font-size: ptr(32px, $fz);
						text-shadow: 0 2px 8px rgba($dark, 0.34);
					}
				}
			}
		}

		&__title {
			&__container {
				padding: ptr(18px, $fz);
				background-color: $light;
				box-shadow: 0 2px 10px rgba($dark, 0.15);
				border-radius: ptr(34px, $fz);
				margin-bottom: 1rem;
			}

			h1 {
				// font-size: ptr(20px, $fz);
				// color: $dark;
				text-align: left;
				// margin: 1rem 0;
				// font-weight: 500;
			}
		}

		&__localisation {
			text-transform: uppercase;
			margin-bottom: 0.5rem;
			color: $dark;
			font-weight: normal;

			i {
				color: $col1;
			}

			span {
				letter-spacing: 1.70px;
			}
		}

		&__ref {
			font-size: ptr(12px, $fz);
			margin-bottom: 0.5rem;
		}

		&__desc {
			color: $col7;
			p {
				color: $col7;
			}
		}

		&__details {
			&__title {
				font-weight: 600;
				font-size: ptr(24px, $fz);
			}

			&__carac {
				margin-top: 0.5rem;

				> i {
					color: $col1;
					margin-right: 0.5rem;
				}

				> span {
					font-size: ptr(14px, $fz);
				}
			}
		}

		&__prix {
			text-align: right;
			margin: 1rem 0 0 0;
			font-size: ptr(32px, $fz);
			font-weight: 800;
			color: $col2;
		}

		&__alert {
			margin: 1rem 0;

			.btn {
				width: 100%;
				box-shadow: 0 2px 10px rgba($dark, .2);
			}
		}

		&__contact {
			padding: ptr(18px, $fz);
			background-color: $light;
			box-shadow: 0 2px 10px rgba($dark, 0.15);
			border-radius: ptr(34px, $fz);
			margin-bottom: 1rem;

			&__form {
				textarea {
					height: ptr(110px, $fz);
				}
				.form-submit {
					text-align: center;
				}
			}
		}

		&__caracteristiques {
			h2 {
				text-align: left;
			}

			ul {
				list-style: none;
				padding: 0;
				margin: 0;
				@include flex(flex, row, wrap, flex-start, stretch);
			}
		}

		&__caracteristique {
			display: block;
			width: calc(50% - 0.5rem);
			text-align: center;
			padding: ptr(18px, $fz);
			background-color: $light;
			box-shadow: 0 2px 10px rgba($dark, 0.15);
			border-radius: ptr(18px, $fz);
			margin: 0.25rem;

			i {
				color: $col1;
				margin-right: 0.25rem;
				vertical-align: middle;
			}

			span {
				text-transform: uppercase;
				color: $dark;
				letter-spacing: 1.7px;
				font-size: ptr(13px, $fz);
			}

			strong {
				color: $col1;
			}

			&.blue {
				background-color: $col1;

				i {
					color: $light;
				}

				span {
					color: $light;
				}
			}
		}

		&__descriptif, 
		&__descriptif__immeuble, 
		&__information__financiere {
			margin: 1.5rem 0;
			color: $col3;

			&__title {
				h2 {
					text-align: left;
				}
			}
		}

		&__consommations,
		&__emissions {
			padding: 1rem;
			border-radius: $bdrs;
			border: 1px solid $col8;
			margin: 2rem 0;

			&__title {

				h2 {
					text-align: left;
					font-size: ptr(24px, $fz);
					font-weight: 500;
				}
			}

			&__graph {
				position: relative;

				&__level {
					margin-bottom: 0.25rem;
					position: relative;

					&__bar {
						@include flex(flex, row, nowrap, space-between, stretch);
						color: $light;
						line-height: ptr(24px, $fz);
						padding: 0 0.5rem;
						position: relative;
						z-index: 2;

						&__minmax {
							font-size: ptr(9px, $fz);
						}

						&__rate {
							font-size: ptr(10px, $fz);
							font-weight: bold;
						}
					}

					&__bien {
						position: absolute;
						z-index: 1;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						background-color: $col9;
						@include flex(flex, row, nowrap, flex-end, stretch);

						span {
							display: block;
							font-size: ptr(10px, $fz);
							line-height: ptr(24px, $fz);
							padding: 0 0.5rem;
							background-color: $dark;
							color: $light;
						}
					}
				}
			}
		}
	}

}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* XS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xs) {

	.bien__detail {
		&__buttons {
			.print,
			.email {
				display: inline-block;
				margin: 0;

				i {
					display: none;
				}
			}
		}

		&__caracteristique {
			margin: 0.5rem;
			width: calc(33% - 1rem);
		}
	}

}


/* ----------------------------------------------------- */
/* SM */
/* ----------------------------------------------------- */
@media (min-width: $screen-sm) {

	.separator {
		margin: 2rem .5rem;
		height: 1px;
		background-color: $col8;
	}

	.bien__detail { 
		padding: 1rem 0;

		.main { order: 1; }
		.aside { order: 2; }

		&__aside {
			padding: 0rem;
			border-radius: $bdrs;
		}

		&__caracteristique {
			width: calc(50% - 1rem);
		}

		&__details {
			&__title {
				font-size: ptr(16px, $fz);
			}
		}

		&__contact {
			padding: 1rem 0;

			&__title {
				display: block;
				font-size: ptr(16px, $fz);
				font-weight: 600;
				text-align: center;
				margin: 0rem 0rem 0.5rem 0rem;

				&__toggle {
					display: none;
				}
			}

			&__form {
				display: block;
				padding: 0rem 1rem;

				input[type="text"], 
				input[type="tel"], 
				input[type="email"], 
				input[type="password"], 
				input[type="search"], 
				input[type="date"] {
					border: none;
					border-radius: 0;
					border-bottom: 1px solid $col8;
				}

				textarea {
					border-color: $col8;
					border-radius: 0;
					height: ptr(110px, $fz);
				}
			}
		}

		&__descriptif, 
		&__descriptif__immeuble, 
		&__information__financiere {
			margin-top: 2rem;

			&__title {
				h2 {
					margin-bottom: 2rem;
				}
			}
		}
	}

}


/* ----------------------------------------------------- */
/* MD */
/* ----------------------------------------------------- */
@media (min-width: $screen-md) {

	.bien__detail { 
		&__title {
			h1 {
				font-size: ptr(28px, $fz);
			}
		}

		&__ref {
			font-size: ptr(16px, $fz);
		}

		&__desc {
			font-size: ptr(18px, $fz);
			p {
				font-size: ptr(18px, $fz);
			}
		}

		&__slider__arrows .slick-arrow {
			&:hover {
				opacity: 1;
			}
		}

		&__caracteristique {
			width: calc(33% - 1rem);
		}

	}

}


/* ----------------------------------------------------- */
/* LG */
/* ----------------------------------------------------- */
@media (min-width: $screen-lg) {

	.bien__detail {
		&__table {
			&__line {
				p {
					font-size: ptr(15px, $fz);
				}
			}
		}

		&__details {
			&__title {
				font-size: ptr(18px, $fz);
				margin-bottom: 1rem;
			}
			&__carac {
				> span {
					font-size: 1rem;
				}
			}
		}

		&__caracteristique {
			width: calc(25% - 1rem);

			i {
				font-size: ptr(18px, $fz);
			}
			span {
				font-size: ptr(15px, $fz);
			}
			strong {
				font-size: ptr(18px, $fz);
			}
		}

		&__map {
			padding-bottom: 3rem;
		}

		&__consommations,
		&__emissions {
			&__graph {
				&__level {
					&__bar {
						line-height: 2rem;
						&__minmax {
							font-size: ptr(10px, $fz);
						}
					}

					&__bien {
						span {
							font-size: ptr(10px, $fz);
							line-height: 2rem;
						}
					}
				}
			}
		}
	}

}


/* ----------------------------------------------------- */
/* XL */
/* ----------------------------------------------------- */
@media (min-width: $screen-xl) {

	.bien__detail {
		&__table {
			&__line {
				p {
					font-size: ptr(16px, $fz);
				}
			}
		}

		&__details {
			&__title {
				font-size: ptr(22px, $fz);
				margin-bottom: 1rem;
			}
			&__carac {
				> span {
					font-size: 1rem;
				}
			}
		}

		&__consommations,
		&__emissions {
			&__graph {
				&__level {
					&__bar {
						line-height: 2rem;
						&__minmax {
							font-size: ptr(10px, $fz);
						}
					}

					&__bien {
						span {
							font-size: ptr(10px, $fz);
							line-height: 2rem;
						}
					}
				}
			}
		}
	}

}

