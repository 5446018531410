/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* LEAFLET */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
// .leaflet-top.leaflet-right
// 	position: absolute
// 	border: 0
// 	border-radius: 0
// 	right: 0
// 	top: 0
// 	margin: 0
// 	padding: 60px 50px
// 	height: 100%
// 	width: 20%
// 	background-color: $light

// .leaflet-control-layers
// 	label
// 		display: block

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* DESKTOP : #{$screen-lg} px */
/* ----------------------------------------------------- */
@media (max-width: $screen-lg) {}

/* ----------------------------------------------------- */
/* TABLETP : #{$screen-md} px */
/* ----------------------------------------------------- */
@media (max-width: $screen-md) {}

/* ----------------------------------------------------- */
/* TABLET : #{$screen-sm} px */
/* ----------------------------------------------------- */
@media (max-width: $screen-sm) {
  // .leaflet-top
  // 	&.leaflet-right
  // 		top: auto
  // 		bottom: 0
  // 		left: 0
  // 		right: 0
  // 		width: auto
  // 		height: auto
  // 		padding: 1em

  // .leaflet-control-layers
  // 	label
  // 		display: inline-block
  // 		vertical-align: top
  // 		padding-right: 1em
}

/* ----------------------------------------------------- */
/* TABLE : #{$screen-xs} px */
/* ----------------------------------------------------- */
@media (max-width: $screen-xs) {}

/* ----------------------------------------------------- */
/* MOBILEP : #{$screen-xxs} px */
/* ----------------------------------------------------- */
@media (max-width: $screen-xxs) {}
