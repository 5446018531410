/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* PAGE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
    .page {


    /* ----------------------------------------------------- */
    /* DATE */
    /* ----------------------------------------------------- */
    &__date {
        color: $col1;
        text-align: left;
        font-weight: bold;
        margin-bottom: 0.5rem;
    }

    /* ----------------------------------------------------- */
    /* TITLE */
    /* ----------------------------------------------------- */
    &__title {
        &__container {
            position: relative;
            text-align: center;
            padding: 0rem;
            margin: 1rem 0;
            overflow: hidden;

            h1 {
                position: relative;
                z-index: 2;
                margin: 0;
                // line-height: 1;
            }
        }
    }

    /* ----------------------------------------------------- */
    /* CATEGORY */
    /* ----------------------------------------------------- */
    &__category {
        .page {
            &__categories {
                list-style: none;
                margin: 2rem 0;
                padding: 0;
            }

            &__categorie {
                display: inline-block;
                vertical-align: top;
                list-style: none;
                margin: 0 ptr(4px, $fz) 0 0;

                .btn {
                    font-style: italic;
                    text-transform: none;
                }

                span {
                    padding: ptr(8px, $fz) ptr(16px, $fz);
                    background-color: $col2;
                    color: $light;
                    font-family: $ff1;
                    font-weight: 800;
                    font-style: italic;
                }
            }
        }
    }
    
    /* ----------------------------------------------------- */
    /* EXCERPT */
    /* ----------------------------------------------------- */
    &__intro,
    &__excerpt {
        padding: 2rem 0;

        p {
            line-height: 2;
            font-family: $ff1;
            font-size: ptr(16px, $fz);
            text-align: center;
        }
    }

    /* ----------------------------------------------------- */
    /* THUMBNAIL */
    /* ----------------------------------------------------- */
    &__thumbnail {
        img {
            filter: grayscale(1);
        }
    }

    /* ----------------------------------------------------- */
    /* PARTAGE */
    /* ----------------------------------------------------- */
    &-share {
        padding: 0 1rem 2rem 1rem;

        &_title {
            color: $dark;
            font-family: Arial;
            font-size: ptr(22px, $fz);
            letter-spacing: 1.47px;
            font-weight: bold;
            text-transform: uppercase;
        }

        &_list {
            list-style: none;
            padding: 0;
            margin: 0;

            &-item {
                display: inline-block;
                vertical-align: top;
                margin-right: 0.5rem;

                &:last-child {
                    margin-right: 0;
                }

                > a {
                    display: block;
                    width: ptr(44px, $fz);
                    height: ptr(44px, $fz);
                    line-height: ptr(44px, $fz);
                    text-align: center;
                    border: 1px solid $col3;
                    background-color: $light;
                    border-radius: 10em;
                    color: $col3;

                    &:hover {
                        color: $col2;
                        border-color: $col2;
                    }
                }
            }
        }
    }

    /* ----------------------------------------------------- */
    /* CONTENU */
    /* ----------------------------------------------------- */
    &__content {
        padding: 0 0 ptr(40px, $fz);
    }

    /* ----------------------------------------------------- */
    /* ONGLETS DE SOUS-CONTENU */
    /* ----------------------------------------------------- */
    &__tabs {
        .tabs {
            padding: 0;
            margin: 0;
            list-style: none;
            @include flex(flex, row, nowrap, center, flex-end);

            @for $i from 1 through 10 {
                &.tabs-count-#{$i} {
                    .tab {
                        flex-basis: calc(100% / #{$i});
                    }
                }
            }

            .tab {
                list-style: none;
                background-color: $col1;
                transition: $cubic;
                position: relative;
                padding: ptr(20px, $fz) ptr(16px, $fz);
                
                &:not(:first-child) {
                    border-left: 1px solid $dark;
                }

                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 0;
                    -webkit-transition: $cubic;
                    -moz-transition: $cubic;
                    -ms-transition: $cubic;
                    -o-transition: $cubic;
                    transition: $cubic;
                    background-color: rgba($dark, 0.35);
                }

                &:hover {
                    background-color: $col1;
                }

                // Couleurs specifiques
                &.tab-profile {
                    background-color: $col3;

                    &:hover {
                        background-color: $col3;
                    }
                }

                &.tab-indicators {
                    background-color: $col2;

                    &:hover {
                        background-color: $col2;
                    }
                }

                &.tab-stakeholders {
                    background-color: $col3;

                    &:hover {
                        background-color: $col3;
                    }
                }

                a {
                    display: block;
                    color: $light;
                    width: 100%;
                    text-align: center;

                    i {
                        font-size: 1.5rem;
                        
                        vertical-align: middle;
                    }

                    span {
                        text-transform: uppercase;
                        display: none;
                    }
                }

                // tab actif
                &.tab-active {
                    padding: ptr(30px, $fz) ptr(16px, $fz);

                    &:before {
                        height: ptr(8px, $fz);
                    }
                }
            }
        }

        &.page-anchors {
            .tabs {
                flex-direction: column;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: stretch;

                .tab {
                    a {
                        i {
                            display: none;
                        }

                        span {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    &__documents {
        @include flex(flex, column, nowrap, flex-end, stretch);

        .btn + .btn {
            margin: 0.5rem 0rem 0rem 0rem;
        }

        .btn {
            i {
                font-size: 1rem;
            }
            span {
                font-size: ptr(12px, $fz);
            }
        }
    }

}


/* ----------------------------------------------------- */
/* CONTENU DES TABS */
/* ----------------------------------------------------- */
.tab-content {
    padding: 0.5rem;
    position: relative;

}


/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* XXS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xxs) {
    .page {
        &__intro,
        &__excerpt {
            font-size: ptr(16px, $fz_xxs);
        }
    }
}

/* ----------------------------------------------------- */
/* XS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xs) {

    .page {
        &__title {
            text-align: left;
        }
    }
}

/* ----------------------------------------------------- */
/* SM */
/* ----------------------------------------------------- */
@media (min-width: $screen-sm) {
    .page {
        &__intro,
        &__excerpt {
            margin: 0;

            p {
                font-size: ptr(18px, $fz);
            }
        }

        &__title {
            &__container {
                padding: 0rem 0;
            }
        }
    }
}

/* ----------------------------------------------------- */
/* MD */
/* ----------------------------------------------------- */
@media (min-width: $screen-md) {
    .page {

        &__title {
            /* Titre avec icon */
            /* ----------------------------------------------------- */
            &-icon {
                @include flex(flex, row, nowrap, center, center);

                .icon-container {
                    margin-bottom: 0;
                    margin-right: 1rem;
                }
            }
        }

        &__intro,
        &__excerpt {
            p {
                font-size: ptr(20px, $fz);
            }
        }

        &__thumbnail {
            img {
                transition: all 0.1s, filter 1s linear;

                &:hover {
                    filter: grayscale(0);
                    box-shadow: 0 3px 6px rgba($dark, .2);
                }
            }
        }

        &__tabs {
            .tabs {
                .tab {
                    a {
                        i {
                            font-size: 2.5rem;
                        }
                    }
                }
            }

            
            &.page-anchors {
                .tabs {
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-items: flex-end;
                }
            }
        }



    }
}

/* ----------------------------------------------------- */
/* LG */
/* ----------------------------------------------------- */
@media (min-width: $screen-lg) {

    .page {
        &__title {
        }

        &__intro,
        &__excerpt {
            font-size: ptr(22px, $fz_lg);
        }
    }

    .tab-content {
        padding: 4rem 0;
    }
}


/* ----------------------------------------------------- */
/* XL */
/* ----------------------------------------------------- */
@media (min-width: $screen-xl) {
    .page {
        &__title {
        }

        &__intro,
        &__excerpt {
            font-size: ptr(24px, $fz_lg);
        }
    }
}
