button {
    -webkit-appearance: none;
    background: none;
    border: none;

    &:focus {
        outline: none;
    }
}

.btn {
    // display: inline-block;
    @include flex(inline-flex, row, nowrap, center, center);
    position: relative;
    padding: ptr(12px, $fz) ptr(16px, $fz);
    transition: $cubic2;

    -webkit-appearance: none;
    border: none;
    border-radius: $bdrs;
    overflow: hidden;

    font-family: $ff1;
    font-size: ptr(16px, $fz);
    font-weight: 600;
    text-align: center;
    line-height: 1;
    text-transform: uppercase;
    text-decoration: none;

    

    & + & {
        margin: 1rem 0 0 0;
    }

    > i {
        display: inline-block;
        position: relative;
        z-index: 2;
        font-size: ptr(18px, $fz);

        &.icn-before {
            margin-right: ptr(20px, $fz);
        }

        &.icn-after {
            margin-left: ptr(20px, $fz);
        }
    }

    &.big-icon {
        > i {
            font-size: 2rem;
        }
    }

    &.small {
        font-family: $ff1;
        font-weight: 800;
        font-size: ptr(12px, $fz);
        padding: ptr(8px, $fz) ptr(16px, $fz);
    }

    span {
        display: inline-block;
        position: relative;
        z-index: 3;
    }

    /* ----------------------------------------------------- */
    /* ICON SEULEMENT */
    /* ----------------------------------------------------- */
    &.icn-only {
        padding: 1rem;

        > i {
            margin: 0;
        }
    }

    /* ----------------------------------------------------- */
    /* FULL LARGEUR */
    /* ----------------------------------------------------- */
    &.full-width {
        width: 100%;
        padding: 1rem;
    }

    /* ----------------------------------------------------- */
    /* BOUTONS RONDS */
    /* ----------------------------------------------------- */
    &.btn-round {
        border-radius: 100rem;
    }

    /* ----------------------------------------------------- */
    /* DEFAULT */
    /* ----------------------------------------------------- */
    &.btn-default {
        color: $light;
        background-color: $col1;

        &:hover {
            color: $light;
            background-color: $col2;
        }
    }

    /* ----------------------------------------------------- */
    /* DEFAULT OUTLINED */
    /* ----------------------------------------------------- */
    &.btn-default-outlined {
        color: $col3;
        border: 1px solid $col3;
        background-color: $light;

        &:hover {
            color: $col3;
            border-color: $col3;
            background-color: $light;
        }
    }

    /* ----------------------------------------------------- */
    /* DARK */
    /* ----------------------------------------------------- */
    &.btn-dark {
        color: $light;
        border: none;
        background-color: $dark;

        &:hover {
            color: $col1;
            background-color: $dark;
        }
    }

    /* ----------------------------------------------------- */
    /* DARK OUTLINED */
    /* ----------------------------------------------------- */
    &.btn-dark-outlined {
        color: $dark;
        border: 1px solid $dark;
        background-color: $light;

        &:hover {
            color: $col3;
            border-color: $col3;
            background-color: $light;
        }
    }

    /* ----------------------------------------------------- */
    /* LIGHT */
    /* ----------------------------------------------------- */
    &.btn-light {
        color: $col1;
        border: none;
        background-color: $light;

        &:hover {
            color: $light;
            background-color: $col1-lighter;
        }
    }

    /* ----------------------------------------------------- */
    /* LIGHT OUTLINED */
    /* ----------------------------------------------------- */
    &.btn-light-outlined {
        color: $light;
        border: 1px solid $light;
        background-color: transparent;

        &:hover {
            color: $col1;
            border-color: $col1;
            background-color: $light;
        }
    }

    /* ----------------------------------------------------- */
    /* PRIMARY */
    /* ----------------------------------------------------- */
    &.btn-primary {
        color: $light;
        border: none;
        background-color: $col2;

        &:hover {
            background-color: $col3;
        }
    }

    /* ----------------------------------------------------- */
    /* PRIMARY OUTLINED */
    /* ----------------------------------------------------- */
    &.btn-primary-outlined {
        color: $col2;
        border: 1px solid $col2;
        background-color: transparent;

        &:hover {
            color: $col2;
            border-color: $col2;
            background-color: transparent;
        }
    }

    /* ----------------------------------------------------- */
    /* SECONDARY */
    /* ----------------------------------------------------- */
    &.btn-secondary {
        color: $col7;
        border: none;
        background-color: $col5;
        border: 2px solid #D0D0D0;

        &:hover {
            color: $col3;
            background-color: $col3;
        }
    }

    /* ----------------------------------------------------- */
    /* ENTOURE SECONDAIRE */
    /* ----------------------------------------------------- */
    &.btn-secondary-outlined {
        color: $col1;
        border: 1px solid $col1;
        background-color: transparent;

        &:hover {
            color: $col1;
            border-color: $col1;
            background-color: $light;
        }
    }

    /* ----------------------------------------------------- */
    /* SOULIGNE */
    /* ----------------------------------------------------- */
    &.btn-underlined {
        color: $col3;
        border-bottom: 2px solid $col3;

        &:hover {
            color: $col2;
            background-color: transparent;
            border-bottom: 2px solid $col2;
        }
    }

    /* ----------------------------------------------------- */
    /* FACEBOOK */
    /* ----------------------------------------------------- */
    &.btn-facebook {
        color: $light;
        background-color: $col-facebook;

        &:hover {
            background-color: darken($col-facebook, 10%);
        }
    }


    /* ----------------------------------------------------- */
    /* GOOGLE */
    /* ----------------------------------------------------- */
    &.btn-google {
        color: $light;
        background-color: $col-google;

        &:hover {
            background-color: darken($col-google, 10%);
        }
    }

    /* ----------------------------------------------------- */
    /* ANIMATIONS */
    /* ----------------------------------------------------- */
    &.btn-balance {
        &:hover {
            > i {
                animation: balance 1s infinite ease-in-out;
            }
        }
    }

    &.btn-balance-vertical {
        &:hover {
            > i {
                animation: ressortv 1s infinite both ease-in-out;
            }
        }
    }

    /* ----------------------------------------------------- */
    /* BTN LINK */
    /* ----------------------------------------------------- */
    &.btn-link {
        text-transform: none;
        color: $col3;
        padding: 0;
        background-color: transparent;

        &:after {
            display: none;
        }

        &:hover {
            color: $dark;
        }

        &.btn-link-default {
            color: $col3;

            &:hover {
                color: $col3;
            }
        }

        &.btn-link-primary {
            color: $col2;

            &:hover {
                color: $col2;
            }
        }

        &.btn-link-secondary {
            color: $col1;

            &:hover {
                color: $col1;
            }
        }
    }

    /* ----------------------------------------------------- */
    /* ANIMATION LOADING */
    /* ----------------------------------------------------- */
    &.loading {
        > i {
            animation: lds-roller 2s infinite;
        }
    }

    
}

.button-group {
    .btn {
        background-color: $light;
        padding: 0;

        > i,
        > span {
            padding: 0.25rem 0.375rem;
        }

        &.btn-default {
            border: 1px solid $col3;
            color: $col3;
            background-color: $light;

            > i {
                background-color: $col2;
            }

            > span {
                text-transform: uppercase;
                font-weight: bold;
            }

            &:hover {
                color: $col3;
                border-color: $col3;
            }
        }
    }
}

button {
    cursor: pointer;
}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* XXS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xxs) {

    .btn {
        font-size: ptr(18px, $fz);
    }

}


/* ----------------------------------------------------- */
/* XS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xs) {

    

}


/* ----------------------------------------------------- */
/* SM */
/* ----------------------------------------------------- */
@media (min-width: $screen-sm) {

    .btn {
        padding: 1rem 2rem;

        &.btn-only {
            padding: 1rem;
        }
    }

}


/* ----------------------------------------------------- */
/* MD */
/* ----------------------------------------------------- */
@media (min-width: $screen-md) {

    .btn {
        -webkit-transition: $trans;
        -moz-transition: $trans;
        -ms-transition: $trans;
        -o-transition: $trans;
        transition: $trans;
        padding: 1rem 2rem;

        &.btn-only {
            padding: 1rem;
        }
    }

}


/* ----------------------------------------------------- */
/* LG */
/* ----------------------------------------------------- */
@media (min-width: $screen-lg) {

    .btn {
        padding: 1rem 2rem;

        &.btn-only {
            padding: 1rem;
        }

        .button-group & {
            > i,
            > span {
                padding: 0.5rem 1rem;
            }
        }
    }

}


/* ----------------------------------------------------- */
/* XL */
/* ----------------------------------------------------- */
@media (min-width: $screen-xl) {

    

}

