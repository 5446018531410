/* Scss Document */
@charset "utf-8";

@import "base/variables";
@import "base/mixin";
@import "critic/icomoon";

@import "base/animations";
@import "base/loader";

// Librairies externes
@import "libs/leaflet/leaflet";
// @import "libs/checkbrowser";

// Composants
@import "component/bootstrap";
@import "component/buttons";
@import "component/breadcrumbs";

@import "component/map";
// @import "component/search";
// @import "component/sitemap";
// @import "component/sommaire";
// @import "component/sliders";
// @import "component/socialwall";
// @import "component/flatpickr";
// @import "component/gallery";
@import "component/popup";
// @import "component/table";
// @import "component/cart";
// @import "component/account";
@import "component/sliders";

// Commun
@import "common/sections";
// @import "common/modules";
@import "common/footer";
@import "common/content";
@import "common/messages";

// Pages
@import "pages/home";
@import "pages/contact";
// @import "pages/programmesneufs";
@import "pages/page";
@import "pages/single";
@import "pages/map";

	// BIENS
	@import "bien/search";
	@import "bien/grid";
	@import "bien/list";
	@import "bien/slider";
	@import "bien/map";
	@import "bien/detail";

	// PROGRAMMES
	// @import "programme/grid";

	// AGENCES
	// @import "agence/detail";

// @import "posts/avis";
@import "posts/pagination";

// Print
@import "print";
