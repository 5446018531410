.bien {
	/* ----------------------------------------------------- */
	/* SLIDER */
	/* ----------------------------------------------------- */
	&__slider {

		&__container {
			.bien__grid__item {
				margin: 0 2px;
			}
		}

		&__arrows {
			position: relative;
			margin: ptr(24px, $fz) 0;
			@include flex(flex, row, nowrap, center, center);

			.slick-arrow {
				position: static;
				margin: 0 ptr(18px, $fz);
				transform: none;
			}
		}
	}
}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* XXS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xxs) {

	

}


/* ----------------------------------------------------- */
/* XS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xs) {

	

}


/* ----------------------------------------------------- */
/* SM */
/* ----------------------------------------------------- */
@media (min-width: $screen-sm) {

	

}


/* ----------------------------------------------------- */
/* MD */
/* ----------------------------------------------------- */
@media (min-width: $screen-md) {

	.bien {
		&__slider {
			.bien__grid__item {
				margin: 0 ptr(14px, $fz);
			}

			&__arrows {
				position: static;

				.slick-arrow {
					overflow: hidden;

					&:hover {
						&.slick-prev {
							i {
								animation: flecheL 1.4s $cubic2trans infinite;
							}
						}
						&.slick-next {
							i {
								animation: flecheR 1.4s $cubic2trans infinite;
							}
						}
					}
				}
			}
		}
	}

}


/* ----------------------------------------------------- */
/* LG */
/* ----------------------------------------------------- */
@media (min-width: $screen-lg) {

	.bien {
		&__slider {

			&__container {
				margin-top: ptr(60px, $fz);
				padding: 0 ptr(68px, $fz);
			}

			&__arrows {
				position: static;

				.slick-arrow {
					position: absolute;
					margin: 0 0.5rem;
					transform: translateY(-50%);
				}
			}
		}
	}

}


/* ----------------------------------------------------- */
/* XL */
/* ----------------------------------------------------- */
@media (min-width: $screen-xl) {

	.bien {
		&__slider {

			&__container {
				padding: 0 ptr(120px, $fz);
			}

		}
	}

}

