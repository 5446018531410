/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* VARIABLES */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
@charset "utf-8";

/* ----------------------------------------------------- */
/* PATHS */
/* ----------------------------------------------------- */
$themePath: "/";
$fontPath: $themePath + "assets/fonts/";
$imagePath: $themePath + "assets/images/";

/* ----------------------------------------------------- */
/* Couleurs */
/* ----------------------------------------------------- */
$light: #ffffff;
$dark: #000000;

/* Specifiques au site */
/* ----------------------------------------------------- */

/* fond */
$col1: #00597C; /* bleu fonce */
	$col1-dark: darken($col1, 5%);
	$col1-darker: darken($col1, 10%);
	$col1-light: lighten($col1, 5%);
	$col1-lighter: lighten($col1, 10%);

$col2: #3EB4E0; /* bleu clair */
	$col2-dark: darken($col2, 5%);
	$col2-darker: darken($col2, 10%);
	$col2-light: lighten($col2, 5%);
	$col2-lighter: lighten($col2, 10%);

$col3: #5F5F5F; /* gris fonce */
	$col3-dark: darken($col3, 5%);
	$col3-darker: darken($col3, 10%);
	$col3-light: lighten($col3, 5%);
	$col3-lighter: lighten($col3, 10%);

$col4: #818181; /* gris moyen */
	$col4-dark: darken($col4, 5%);
	$col4-darker: darken($col4, 10%);
	$col4-light: lighten($col4, 5%);
	$col4-lighter: lighten($col4, 10%);

$col5: #F6F6F6; /* gris clair3 */
	$col5-dark: darken($col5, 5%);
	$col5-darker: darken($col5, 10%);
	$col5-light: lighten($col5, 5%);
	$col5-lighter: lighten($col5, 10%);

$col6: #797979; /* gris moyen */
	$col6-dark: darken($col6, 5%);
	$col6-darker: darken($col6, 10%);
	$col6-light: lighten($col6, 5%);
	$col6-lighter: lighten($col6, 10%);

$col7: #403F3F; /* gris fonce */
	$col7-dark: darken($col7, 5%);
	$col7-darker: darken($col7, 10%);
	$col7-light: lighten($col7, 5%);
	$col7-lighter: lighten($col7, 10%);

$col8: #D9D9D9; /* encore un gris */
$col9: #ECECEC; /* encore encore un gris */


$success: #0CBB77;
$danger: #AA1451;
$warning: #F68402;

$col-facebook: #43619C;
$col-google: #D8D8D8;

/* ----------------------------------------------------- */
/* FONTS */
/* ----------------------------------------------------- */
$ff1: 'SourceSansPro', 'Verdana', sans-serif;
$ff2: 'Arial', Times, serif;
$ff3: $ff1;
$ff4: $ff1;

$fficon: "icomoon";
$ffa: "FontAwesome";

/* Font family des elements */
/* ----------------------------------------------------- */
$ffh1: $ff1;
$ffh2: $ff1;
$ffh3: $ff1;
$ffh4: $ff1;
$ffh5: $ff1;
$ffh6: $ff1;

$fftext: $ff1;
$fflist: $ff1;

$fflink: $ff1;
$fflink_h: $ff1;

/* Correspondances couleurs -> texte */
/* ----------------------------------------------------- */
$colh1: $col1;
$colh2: $col1;
$colh3: $col1;
$colh4: $col2;
$colh5: $col2;
$colh6: $col2;

$coltext: $col3;
$collist: $col3;

$collink: $col1;
$collink_h: $col2;

/* ----------------------------------------------------- */
/* FONT-SIZES */
/* ----------------------------------------------------- */
$fz: 16px;
$fz_xxs: 16px;
$fz_xs: 16px;
$fz_sm: 16px;
$fz_md: 16px;
$fz_lg: 16px;
$fz_xl: 16px;

/* ----------------------------------------------------- */
/* TRANSITIONS */
/* ----------------------------------------------------- */
$cubictrans: cubic-bezier(0.12, 0.9, 0.79, 1);
$cubic: all 1.4s $cubictrans;

$cubic2trans: cubic-bezier(0.14, 0.66, 0.28, 0.93);
$cubic2: all 0.4s $cubic2trans;

$trans: all .6s ease-out;
$eltrans: all 2s cubic-bezier(0.12, 0.9, 0.79, 1), opacity 2s linear;

/* ----------------------------------------------------- */
/* BASES PADDING & MARGIN */
/* ----------------------------------------------------- */
$base-padding: 16px;
$base-margin: 20px;

/* ----------------------------------------------------- */
/* BORDER RADIUS */
/* ----------------------------------------------------- */
$bdrs: 0px;

/* ----------------------------------------------------- */
/* CONTAINERS */
/* ----------------------------------------------------- */
$container-width: 1500px;

/* ----------------------------------------------------- */
/* HEADER */
/* ----------------------------------------------------- */

$hdr-h: 75px;
$hdr-h_xs: 75px;
$hdr-h_sm: 120px;
$hdr-h_md: 120px;
$hdr-h_lg: 120px;
$hdr-reduced-h: 120px;

/* ----------------------------------------------------- */
/* SIDEBAR */
/* ----------------------------------------------------- */

$asd_h: 60px;
$asd_h_xl: 85px;
$asd_w_md: 28vh;
$asd_w_lg: 320px;

/* ----------------------------------------------------- */
/* FOOTER */
/* ----------------------------------------------------- */

$ftr_bg: $col1;

/* ----------------------------------------------------- */
/* BREAKPOINTS */
/* ----------------------------------------------------- */
$screen-xxs: 480px;
$screen-xs: 576px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;
$screen-xl: 1600px;

/* ----------------------------------------------------- */
/* EASING */
/* ----------------------------------------------------- */
$easeInSine: cubic-bezier(0.47, 0, 0.745, 0.715);
$easeOutSine: cubic-bezier(0.39, 0.575, 0.565, 1);
$easeInOutSine: cubic-bezier(0.445, 0.05, 0.55, 0.95);
$easeInQuad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeInOutQuad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$easeInCubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1);
$easeInQuart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeInOutQuart: cubic-bezier(0.77, 0, 0.175, 1);
$easeInQuint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);
$easeInOutQuint: cubic-bezier(0.86, 0, 0.07, 1);
$easeInExpo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeInOutExpo: cubic-bezier(1, 0, 0, 1);
$easeInCirc: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$easeOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);
$easeInOutCirc: cubic-bezier(0.785, 0.135, 0.15, 0.86);
$easeInBack: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$easeInOutBack: cubic-bezier(0.68, -0.55, 0.265, 1.55);
