/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* MIXIN.SCSS */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* FONT FACE */
/* ----------------------------------------------------- */
@mixin font-face($name, $file, $weight, $style) {
    @font-face {
        font-family: $name;
        src: url("#{$file}.eot");
        src: url("#{$file}.eot?#iefix") format("embedded-opentype"), url("#{$file}.woff") format("woff"), url("#{$file}.ttf") format("truetype"), url("#{$file}.svg") format("svg");
        font-weight: $weight;
        font-style: $style;
    }
}

/* ----------------------------------------------------- */
/* KEYFRAMES */
/* ----------------------------------------------------- */
@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }

    @-moz-keyframes #{$name} {
        @content;
    }

    @keyframes #{$name} {
        @content;
    }
}

@mixin degradeAnime($type, $angle, $duration) {

    // @if $type == bleu {
    //     background-color: $col2;
    //     background-image: radial-gradient(ellipse, $col2 0%, rgba(17,43,102,1) 100%);
    // }

    // @else if $type == bleubruit {
    //     background-color: $col2;
    //     background-image: url($imagePath + 'noise.png'), radial-gradient(ellipse, $col2 0%, rgba(17,43,102,1) 100%);
    // }

    // @else if $type == bleutransparent {
    //     background-image: linear-gradient(90deg, rgba($col2,1) 0%, rgba($col2,0) 100%);
    // }

    // @else if $type == rouge {
    //     background-color: $col3;
    //     background-image: radial-gradient(circle, $col3 0%, darken($col3, 20%) 100%);
    // }

    // @else if $type == rougebruit {
    //     background-color: $col3;
    //     background-image: url($imagePath + 'noise.png'), radial-gradient(circle, $col3 0%, rgba(170,0,6,1) 100%);
    // }

    // @else if $type == rougetransparent {
    //     background-image: linear-gradient(90deg, rgba($col3,1) 0%, rgba($col3,0) 100%);
    // }

    // @else if $type == verttransparent {
    //     background-image: linear-gradient(90deg, rgba($col2,1) 0%, rgba($col2,0) 100%);
    // }

    // @else if $type == noirtransparent {
    //     background-image: linear-gradient(90deg, rgba($dark,1) 0%, rgba($dark,0) 100%);
    // }

    // @else if $type == bleubleu {
    //     background-color: $col3;
    //     background-image: linear-gradient($angle, $col2 0%, $col2 30%, $col3 80%, $col3 100%);
    // }

    // @else if $type == bleurouge {
    //     background-color: $col2;
    //     background-image: linear-gradient($angle, $col2 0%, $col2 30%, $col3 80%, $col3 100%);
    // }

    // @else if $type == bleurougebruit {
    //     background-color: $col2;
    //     background-image: url("/fflutte/content/themes/fflutte/assets/images/noise.png"), linear-gradient($angle, $col2 0%, $col2 30%, $col3 80%, $col3 100%);
    // }

    // @else if $type == rougebleu {
    //     background-color: $col2;
    //     background-image: linear-gradient($angle, $col3 0%, $col3 30%, $col2 80%, $col2 100%);
    // }

    // @else if $type == rougebleubruit {
    //     background-color: $col2;
    //     background-image: url("/fflutte/content/themes/fflutte/assets/images/noise.png"), linear-gradient($angle, $col3 0%, $col3 30%, $col2 80%, $col2 100%);
    // }

    // background-size: 200% 200%;
    // -webkit-animation: degradeAnime $duration ease infinite;
    // -moz-animation: degradeAnime $duration ease infinite;
    // animation: degradeAnime $duration ease infinite;

}

@include keyframes(degradeAnime) {
    0%{
        background-position: 0% 51%;
    }
    50%{
        background-position: 100% 50%;
    }
    100%{
        background-position: 0% 50%;
    }
}


/* ----------------------------------------------------- */
/* CENTRE UNE IMAGE */
/* ----------------------------------------------------- */
@mixin imgCenter {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: auto;
    transform: translate(-50%, -50%);
}

/* ----------------------------------------------------- */
/* PREFIXES : Transition */
/* ----------------------------------------------------- */
@mixin transition($trans) {
    -ms-transition: $trans;
    -o-transition: $trans;
    -moz-transition: $trans;
    -webkit-transition: $trans;
    transition: $trans;
}

/* ----------------------------------------------------- */
/* PREFIXES : Transform */
/* ----------------------------------------------------- */
@mixin transform($transf) {
    -ms-transform: $transf;
    -o-transform: $transf;
    -moz-transform: $transf;
    -webkit-transform: $transf;
    transform: $transf;
}

/* ----------------------------------------------------- */
/* CENTRE UN ELEMENT */
/* ----------------------------------------------------- */
@mixin centerEl {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

/* ----------------------------------------------------- */
/* BASE GRADIENT */
/* ----------------------------------------------------- */
@mixin degrade($type, $angle: 90deg) {
    // @if $type == bleu {
    //     background-color: $col2;
    //     background-image: radial-gradient(ellipse, $col2 0%, rgba(17,43,102,1) 100%);
    // }

    // @else if $type == bleutransparent {
    //     background-image: linear-gradient($angle, rgba($col2,1) 0%, rgba($col2,0) 100%);
    // }

    // @else if $type == bleubruit {
    //     background-color: $col2;
    //     // background-image: url($imagePath + 'noise.png'), radial-gradient(ellipse, $col2 0%, rgba(17,43,102,1) 100%);
    //     background-image: url($imagePath + 'noise.png'), radial-gradient(ellipse, $col2 0%, rgba(3,23,67,1) 100%);
    // }

    // @else if $type == rouge {
    //     background-color: $col3;
    //     background-image: radial-gradient(circle, $col3 0%, rgba(170,0,6,1) 100%);
    // }

    // @else if $type == rougetransparent {
    //     background-color: $col3;
    //     background-image: linear-gradient($angle, rgba($col3,1) 0%, rgba($col3,0) 100%);
    // }

    // @else if $type == rougebruit {
    //     background-color: $col3;
    //     background-image: url($imagePath + 'noise.png'), radial-gradient(circle, $col3 0%, rgba(170,0,6,1) 100%);
    // }

    // @else if $type == bleurouge {
    //     background-image: linear-gradient($angle, $col2 0%, $col3 100%);
    // }

    // @else if $type == bleurougebruit {
    //     background-color: $col2;
    //     background-image: url("/fflutte/content/themes/fflutte/assets/images/noise.png"), linear-gradient($angle, $col2 0%, $col3 100%);
    // }

    // @else if $type == rougebleu {
    //     background-color: $col2;
    //     background-image: linear-gradient($angle, $col3 0%, $col2 100%);
    // }

    // @else if $type == bleubleu {
    //     background-color: $col3;
    //     background-image: linear-gradient($angle, $col2 0%, $col2 30%, $col3 80%, $col3 100%);
    // }

    // @else if $type == rougebleubruit {
    //     background-color: $col2;
    //     background-image: url("/fflutte/content/themes/fflutte/assets/images/noise.png"), linear-gradient($angle, $col3 0%, $col2 100%);
    // }
}

/* ----------------------------------------------------- */
/* CENTRE UN BACKGROUND QUAND ON LE MET EN INLINE */
/* ----------------------------------------------------- */
@mixin centerBG {
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
}

/* ----------------------------------------------------- */
/* OBJECT FIT AVEC DETECTIZR */
/* ----------------------------------------------------- */
@mixin objFit {
    html.objectfit & {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
    }

    html:not(.objectfit) & {
        width: 100%;
        height: auto;
    }
}

/* ----------------------------------------------------- */
/* TRIANGLES */
/* ----------------------------------------------------- */
@mixin triangle($orientation, $color, $width, $height) {
    width: 0;
    height: 0;

    @if $orientation == "top" {
        top: 1px;
        left: 50%;
        -webkit-transform: translate(-100%, -50%);
        -ms-transform: translate(-100%, -50%);
        -o-transform: translate(-100%, -50%);
        transform: translate(-100%, -50%);
        border-top: $height solid transparent;
        border-right: $width solid transparent;
        border-bottom: $height solid $color;
        border-left: $width solid transparent;
    }

    @if $orientation == "right" {
        top: 50%;
        right: 1px;
        -webkit-transform: translate(100%, -50%);
        -ms-transform: translate(100%, -50%);
        -o-transform: translate(100%, -50%);
        transform: translate(100%, -50%);
        border-top: $height solid transparent;
        border-right: $width solid transparent;
        border-bottom: $height solid transparent;
        border-left: $width solid $color;
    }

    @if $orientation == "bottom" {
        bottom: 1px;
        left: 50%;
        -webkit-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        transform: translate(-50%, 0%);
        border-top: $height solid $color;
        border-right: $width solid transparent;
        border-bottom: $height solid transparent;
        border-left: $width solid transparent;
    }

    @if $orientation == "left" {
        top: 50%;
        left: 1px;
        -webkit-transform: translate(-100%, -50%);
        -ms-transform: translate(-100%, -50%);
        -o-transform: translate(-100%, -50%);
        transform: translate(-100%, -50%);
        border-top: $height solid transparent;
        border-right: $width solid $color;
        border-bottom: $height solid transparent;
        border-left: $width solid transparent;
    }
}

/* ----------------------------------------------------- */
/* Display flex */
/* ----------------------------------------------------- */
@mixin flex($flex: flex, $direction: row, $wrap: nowrap, $jc: flex-start, $ai: flex-start) {
    display: -webkit-#{$flex};
    display: -moz-#{$flex};
    display: -ms-#{$flex};
    display: -o-#{$flex};
    display: #{$flex};
    flex-direction: $direction;
    flex-wrap: $wrap;
    justify-content: $jc;
    align-items: $ai;
}

/* ----------------------------------------------------- */
/* Object Fit */
/* ----------------------------------------------------- */
@mixin objfit($fit: cover, $pos: center) {
    width: 100%;
    height: 100%;
    object-fit: $fit;
    object-position: $pos;
}

/* ----------------------------------------------------- */
/* Hexagones */
/* ----------------------------------------------------- */
@mixin hex($svg) {

    background-image: url($imagePath + $svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;

}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* FONCTIONS */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* CONVERSION PX -> EM */
/* ----------------------------------------------------- */
@function pte($px, $fz) {
    @return $px / $fz * 1em;
}

/* ----------------------------------------------------- */
/* CONVERSION PX -> REM */
/* ----------------------------------------------------- */
@function ptr($px, $fz) {
    @return $px / $fz * 1rem;
}

/* ----------------------------------------------------- */
/* CONVERSION PX -> % */
/* ----------------------------------------------------- */
@function ptp($lg, $ct) {
    @return $ct / 100% * $lg;
}
