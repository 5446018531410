/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* PAGINATION */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

.items {

	/* ----------------------------------------------------- */
	/* PAGINATION */
	/* ----------------------------------------------------- */
	&__pagination {
		margin: 2rem 0;
		text-align: center;

		.nav {
			list-style: none;
			padding: 0;
			margin: 0;
			text-align: center;
			justify-content: center;

			.page-item,
			.page {
				display: inline-block;
				vertical-align: bottom;
				list-style: none;
				padding: 0 .25rem;

				> a,
				> span {
					display: block;
					font-size: ptr(16px, $fz);
					font-weight: bold;
					color: $light;
					background-color: rgba($col4, 0.4);
					border-radius: 100rem;
					width: ptr(40px, $fz);
					height: ptr(40px, $fz);
					line-height: ptr(40px, $fz);
				}

				&.active {
					span {
						color: $light;
						background-color: $dark;
					}
				}

				&.prev,
				&.next {
					> span,
					> a {
						background-color: rgba($col4, 1);
					}
				}
			}
		}
	}

}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* XXS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xxs) {

	

}


/* ----------------------------------------------------- */
/* XS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xs) {

	

}


/* ----------------------------------------------------- */
/* SM */
/* ----------------------------------------------------- */
@media (min-width: $screen-sm) {

	

}


/* ----------------------------------------------------- */
/* MD */
/* ----------------------------------------------------- */
@media (min-width: $screen-md) {

	.items {
		&__pagination {
			.nav {
				.page {
					&:hover {
						> a {
							color: $light;
							background-color: $col4;

						}

						&.prev,
						&.next {
							> a {
								color: $light;
								background-color: $dark;
							}
						}
					}
				}
			}
		}
	}

}


/* ----------------------------------------------------- */
/* LG */
/* ----------------------------------------------------- */
@media (min-width: $screen-lg) {

	

}


/* ----------------------------------------------------- */
/* XL */
/* ----------------------------------------------------- */
@media (min-width: $screen-xl) {

	

}

