.card {
	border: none;
	border-radius: 0;

	&-header {
		background-color: $light;
		padding: 0.5rem;
		margin-bottom: 0;

		.btn {
			text-align: left;
			width: 100%;

			> span {
				text-transform: uppercase;
				font-weight: bold;
				color: $col3;
			}

			> i {
				position: absolute;
				top: 50%;
				right: 0;
				transform: translate(0,-50%) rotate(180deg);
				transition: $trans;
				font-size: ptr(6px, $fz);
				color: $col3;
			}

			&.collapsed {
				> i {
					-webkit-transform: translate(0,-50%) rotate(0deg);
					-ms-transform: translate(0,-50%) rotate(0deg);
					-o-transform: translate(0,-50%) rotate(0deg);
					transform: translate(0,-50%) rotate(0deg);
					color: $dark;
				}

				> span {
					color: $dark;
				}
			}
		}
	}

	&-body {
		padding: 0.5rem;

		p {
			font-size: ptr(14px, $fz);
		}
	}
}

/* ----------------------------------------------------- */
/* ACCORDEON */
/* ----------------------------------------------------- */
.accordion {
	>.card {
		.card-header {
			margin-bottom: 0px;
		}
	}
}

/* ----------------------------------------------------- */
/* ONGLETS */
/* ----------------------------------------------------- */
.nav-tabs {
	border: none;

	.nav-item {
		margin-bottom: 0;
	} 

	.nav-item.show .nav-link,
	.nav-link.active {
		color: inherit;

		&:after {
			width: 100%;
		}
	}

	.nav-link {
		border: none;
		font-weight: bold;
		color: $col1;
		text-transform: uppercase;
		position: relative;

		&:after {
			content: "";
			display: block;
			position: absolute;
			top: 100%;
			left: 50%;
			-webkit-transform: translate(-50%,0);
			-ms-transform: translate(-50%,0);
			-o-transform: translate(-50%,0);
			transform: translate(-50%,0);
			width: 0;
			height: 3px;
			background-color: $col3;
			-webkit-transition: $trans;
			-moz-transition: $trans;
			-ms-transition: $trans;
			-o-transition: $trans;
			transition: $trans;
		}

		&:hover {
			&:after {
				width: 60%;
			}
		}
	}
}



/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* XXS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xxs) {

	

}


/* ----------------------------------------------------- */
/* XS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xs) {

	.card {
		&-header {
			padding: 1rem;
		}

		&-body {
			padding: 1rem;
		}
	}

}


/* ----------------------------------------------------- */
/* SM */
/* ----------------------------------------------------- */
@media (min-width: $screen-sm) {

	

}


/* ----------------------------------------------------- */
/* MD */
/* ----------------------------------------------------- */
@media (min-width: $screen-md) {

	

}


/* ----------------------------------------------------- */
/* LG */
/* ----------------------------------------------------- */
@media (min-width: $screen-lg) {

	.nav-tabs {
		.nav-link {
			font-size: ptr(22px, $fz_md);
		}
	}

}


/* ----------------------------------------------------- */
/* XL */
/* ----------------------------------------------------- */
@media (min-width: $screen-xl) {

	

}

