/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* SINGLES */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

.single {

	.details {
		position: relative;

		&:before {
			content: url($imagePath + 'illustration_1.svg');
			display: block;
			position: absolute;
			bottom: 4rem;
			right: 0;
			width: 30%;
			max-width: 433px;
			-webkit-transform: translate(50%, 0);
			-ms-transform: translate(50%, 0);
			-o-transform: translate(50%, 0);
			transform: translate(50%, 0);
			z-index: 0;
			opacity: .5;
		}
	}


	/* ----------------------------------------------------- */
	/* IMAGE */
	/* ----------------------------------------------------- */
	&__image {
		height: 100%;

		img {
			@include objfit();
		}
	}

	/* ----------------------------------------------------- */
	/* TITLE */
	/* ----------------------------------------------------- */
	&__title {
		padding: 1rem 0;

		.page-title {
			color: $col3;
			font-family: $ff2;
			font-size: 45px;
			font-weight: bold;
		}
	}

	/* ----------------------------------------------------- */
	/* TAGS */
	/* ----------------------------------------------------- */
	&__tags {
		margin: 0 0 1rem 0;

		.tag {
			display: inline-block;
			vertical-align: middle;
			margin: 0 10px 0 0;
			border-radius: 4px;
			padding: 6px;
			background: $col2;
			color: $light;
		}
	}

	/* ----------------------------------------------------- */
	/* SHARE (partage sur les reseaux sociaux) */
	/* ----------------------------------------------------- */
	&__share {
		color: #8F8F8F;
		font-family: $ff2;
		font-size: 1rem;
		text-transform: uppercase;

		padding-bottom: 1rem;
		border-bottom: 1px solid #8F8F8F;
		margin: 1rem 0;

		a {
			color: #8F8F8F;
		}
		
		> div {
			display: inline-block;
			vertical-align: middle;

			&:not(:last-child) {
				margin-right: ptr(40px, $fz);
			}

		}


		.share {
			position: relative;

			&__platforms {
				position: absolute;
				top: 100%;
				left: 0;
				width: 100%;
				text-align: center;
				background: $light;
				padding: 1rem;
				list-style: none;
				box-shadow: 0 0 12px rgba(0,0,0,0.2);
				opacity: 0;
				visibility: hidden;
				-webkit-transition: $trans;
				-moz-transition: $trans;
				-ms-transition: $trans;
				-o-transition: $trans;
				transition: $trans;
				margin: 0;
				
				li {
					list-style: none;
					
					a {
						font-size: 2rem;
					}
				}
			}

			&-toggle {
				&:hover,
				&:focus,
				&:active {
					& ~ .share__platforms {
						opacity: 1;
						visibility: visible;
					}
				}

			}
		}

	}

	/* ----------------------------------------------------- */
	/* DESCRIPTION */
	/* ----------------------------------------------------- */
	&__description {
		margin-bottom: ptr(48px, $fz);
	}

	/* ----------------------------------------------------- */
	/* CONTACT */
	/* ----------------------------------------------------- */
	&__contact {

		.section_title {
			font-size: ptr(36px, $fz);
			margin-bottom: ptr(18px, $fz);
		}

		&_name {
			font-weight: bold;
		}

		&_website {
			margin-top: ptr(48px, $fz);
		}

		&_socials {
			text-align: center;

			.btn {
				width: ptr(44px, $fz);
				height: ptr(44px, $fz);
				border: 2px solid $col3;
				color: $col3;
				padding: 0;
				line-height: ptr(40px, $fz);

				> i {
					margin-right: 0;
				}
			}
		}
	}

	/* ----------------------------------------------------- */
	/* TABS */
	/* ----------------------------------------------------- */
	&__tabs {

		.tabs {
			list-style: none;
			padding: 0;
			margin: 0;

			.tab {
				list-style: none;
				
				&-button {
					background-color: $light;
					color: $col3;
					text-transform: uppercase;
					font-family: $ff2;
					font-weight: bold;
					padding: 2rem;
					text-align: center;
					display: block;
					border: 1px solid #D8D8D8;
				}

				&.tab-active {
					.tab-button {
						background-color: $col3;
						color: $light;
					}
				}
			}

			.tab-content-mobile {
				// tab-content-active
				max-height: 0rem;
				padding: 0rem 1rem;
				-webkit-transition: $trans;
				-moz-transition: $trans;
				-ms-transition: $trans;
				-o-transition: $trans;
				transition: $trans;
				overflow: hidden;

				&.tab-content-active {
					max-height: 100rem;
					padding: 2rem 1rem;
				}
			}
		}

		.tab-contents {
			display: none;
			padding: 2rem 0;
		}

	}

	/* ----------------------------------------------------- */
	/* POST */
	/* ----------------------------------------------------- */
	&__post {
		overflow: hidden;
		

		&__back {
			margin-left: 1.875rem;
		}

		&__date__image {
			padding: 1rem;
			color: $light;
			position: relative;
			@include degrade(bleubruit);

			&:after {
				content: "";
				display: none;
				
				position: absolute;
				top: -1px;
				bottom: -1px;
				right: -1px;
				width: 20%;

				background-color: $light;
				-webkit-transform: skewX(-15deg);
				-moz-transform: skewX(-15deg);
				-ms-transform: skewX(-15deg);
				-o-transform: skewX(-15deg);
				transform: skewX(-15deg);
				transform-origin: bottom left;
			}
		}

		&__content {
			&__date {
				font-size: 3rem;
				color: rgba($light, .3);
				font-weight: bold;
			}
		}

		&__title {
			font-size: ptr(18px, $fz);
		}


		/* Other titles */
		/* ----------------------------------------------------- */
		&__subtitle {
			margin: 1rem 0;
		}

		&__image {
			display: none;
		}

		&__details {
			ul {
				list-style: none;
				padding: 0;
				margin: 0;

				li {
					list-style: none;
					padding: 0;
					margin: 0;

					span {
						display: inline-block;
						vertical-align: top;
						background-color: $light;
						margin: .25rem 0;
						padding: .25rem;
						font-size: ptr(9px, $fz);
						text-transform: uppercase;
						font-family: $ff1;
					}
				}
			}
		}

		&__sections {
			background-color: #F2F5F8;
		}

		&__contenu {
			background-color: #F2F5F8;
		}

		/* Mediatheques  des posts */
		/* ----------------------------------------------------- */
		&__attachments {
			padding: 0;

			.post__container.post-grid {
				overflow: hidden;

				@for $i from 1 through 10 {
					&.post-grid-#{$i} {
						grid-template-rows: repeat( $i+1, minmax(20vh, 30vh));
					}
				}

				.item {
					padding: 0;

					&__content {
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						padding: .5rem !important;
						@include degrade('bleutransparent');
						@include flex(flex, column, nowrap, flex-end, flex-start);

						&__tags {
							list-style: none;
							padding: 0;
							margin: 0;

							.tag {
								list-style: none;
								display: inline-block;
								vertical-align: middle;
								padding: ptr(8px, $fz) ptr(16px, $fz);
								background-color: $light;
								text-transform: uppercase;
								color: $col1;
								font-family: $ff1;
								font-size: ptr(12px, $fz);
								margin-right: ptr(4px, $fz);
								margin-bottom: ptr(4px, $fz);
							}
						}
					}
				}
			}
		}

		/* Evenements suggeres */
		/* ----------------------------------------------------- */
		&__others {
			margin-top: 3rem;
			padding-top: 3rem;
			border-top: 1px solid rgba($dark, .5);

			.other_posts {
				h3 {
					margin-bottom: 3rem;
				}
			}
		}

	}

	/* ----------------------------------------------------- */
	/* EVENT */
	/* ----------------------------------------------------- */
	&__event {
		&__contenu {
			p {
				font-size: .875rem;

				a {
					color: $col3;
					font-weight: bold;
				}
			}

			.contenu {
				padding: 1rem;
			}

			.details {
				background-color: #F2F5F8;
				padding: 1rem;

				.single__event__details {
					&__title {
						font-weight: bold;
					}

					&__programme {
						margin-bottom: 1rem;
					}

					&__circulaire {
						margin-bottom: 1rem;
					}

					&__contact {
						.nom,
						.telephone,
						.email,
						.website {
							display: block;
						}
					}
				}
			}
		}
	}

}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* XXS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xxs) {

	

}


/* ----------------------------------------------------- */
/* XS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xs) {

	.single {
		/* CONTACT */
		/* ----------------------------------------------------- */
		&__contact {

			&_website-networks {
				@include flex(flex, row, nowrap, space-between, flex-end);
			}

		}

		&__title {
			.page-title {
				text-align: left;
			}
		}

		/* ----------------------------------------------------- */
		/* POST */
		/* ----------------------------------------------------- */
		&__post {
			&__date__image {
				@include flex(flex, row, nowrap, flex-start, center);

				&:after {
					display: block;
				}
			}

			&__content {
				flex-basis: 50%;
				padding-right: 1rem;
			}

			&__image {
				position: relative;
				z-index: 2;
				flex-basis: 50%;
				display: block;
				padding-left: 1rem;

				img {
					border: 3px solid white;
					display: block;
					box-shadow: 0 3px 6px rgba($dark,0.5);
				}

				
			}
		}

		&__event {

			.single__post {
				&__image {
					padding-left: 0;
					padding-right: 1rem;
					position: relative;
					z-index: 5;
				}

				&__content {
					padding-left: 1rem;
					padding-right: 0;
					position: relative;
					z-index: 5;

					&__date {
						color: #bac2d7;
					}
				}

				&__title {
					color: #153776;
				}
			}


			.single__post__date__image:after {
				width: 80%;
				z-index: 3;
			}

			&__contenu {
				@include flex(flex, row, nowrap, flex-start, stretch);

				.contenu,
				.details {
					flex-basis: 50%;
				}
			}
		}

	}

}


/* ----------------------------------------------------- */
/* SM */
/* ----------------------------------------------------- */
@media (min-width: $screen-sm) {

	.single {
		&__post {
			&__sections,
			&__contenu {
				padding: 1rem;
			}

			&__content {
				&__date {
					font-size: 5rem;
				}
			}
		}
	}

}


/* ----------------------------------------------------- */
/* MD */
/* ----------------------------------------------------- */
@media (min-width: $screen-md) {

	.single {

		&__tabs {

			.tabs {
				@include flex(flex, row, nowrap, flex-start, center);

				.tab-content-mobile {
					display: none;
				}
			}

			.tab-contents {
				display: block;
			}
		}

		&__post {
			overflow: visible;

			&__back {
				display: block;
				margin-bottom: 2rem;
			}
			&__date__image {
				padding: 3rem 1rem;

				&:before {
					content: "";
					display: block;
					position: absolute;
					z-index: 1;
					width: 1000%;
					height: 100%;
					top: 0;
					right: 20%;
					@include degrade(bleubruit);
				}
			}

			&__content {
				position: relative;
				z-index: 2;
			}

			&__title {
				font-size: 2rem;
				margin-bottom: 2rem;
			}

			&__image {
				padding-left: 0;
			}

			&__attachments {
				margin-top: 3rem;
			}
		}

		&__event {
			&__contenu {
				p {
					a {
						&:hover {
							color: $col2;
						}
					}
				}
			}
		}
	}


}


/* ----------------------------------------------------- */
/* LG */
/* ----------------------------------------------------- */
@media (min-width: $screen-lg) {

	.single {
		&__event {
			&__contenu {
				.contenu,
				.details {
					padding: 3rem 2rem;

					p {
						font-size: 1rem;
					}
				}
			}
		}
	}

}


/* ----------------------------------------------------- */
/* XL */
/* ----------------------------------------------------- */
@media (min-width: $screen-xl) {

	.single {
		&__post {
			&__content {
				&__date {
					font-size: 9rem;
				}
			}
		}
	}

}


