/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* CARTE INTERACTIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* ACCUEIL */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* PAGE CARTE */
/* ----------------------------------------------------- */
#map {
    height: 50vh;
}

/* Conteneur Map */
/* ----------------------------------------------------- */
.content-map {
    position: relative;

    /* On cache la barre de partage */
    /* ----------------------------------------------------- */
    .sharedaddy {
        display: none;
    }

    /* Conteneur Map */
    /* ----------------------------------------------------- */
    #map {
        height: 80vh;
    }

    /* Filtres */
    /* ----------------------------------------------------- */
    .map-filtres {
        position: absolute;
        top: 0px;
        right: 0;
        width: 25%;
        background: $col2;
        max-width: 350px;
        padding: 30px 50px;
        border-radius: 0px 0 0px 45px;
        color: $light;
        font-size: 2rem;
        box-shadow: 0 0 30px rgba($dark, 0.3);
        text-align: center;
        z-index: 29;

        p {
            border-bottom: 1px solid white;
            padding-bottom: 20px;
            margin-top: 0;
        }

        .form-group {
            display: block;
            margin-bottom: $base-padding;

            label {
                font-size: 0.875em;
            }

            select {
                display: block;
                width: 100%;
                height: 58px;
                line-height: 58px;
                background-color: rgba($light, 0.25);
                color: $light;
                font-size: 0.875em;
                border: none;

                option {
                    font-size: 0.875em;
                    color: $light;
                }
            }
        }
    }
}

/* ----------------------------------------------------- */
/* INFOWINDOW / POPUP */
/* ----------------------------------------------------- */

/* GOOGLE */
/* ----------------------------------------------------- */
.gm-style-iw {
    padding: $base-padding;

    .title {
        padding: $base-padding 0;
        background-color: $light;
        color: $col1;
        font-size: 1.75rem;
        font-weight: bold;
        text-align: center;

        // border-radius: 0 0 20px 20px
 
    }

    .image {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        min-height: 140px;
        position: relative;
        overflow: hidden;

        img {
            position: absolute;
            width: 100%;
            height: auto;

            @include imgCenter;
        }
    }

    .content {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        padding-top: $base-padding;
        font-size: 1.25rem !important;
        color: $col1;
    }

    .link {
        text-align: right;

        .btn {
            padding: 10px 20px;
        }
    }
}

/* OSM / IGN / LEAFLET */
/* ----------------------------------------------------- */
.leaflet-popup-content {
    margin: 0;
    padding: 0;

    &-wrapper {
        background-color: $light;
        border-radius: 0px;
        padding: 0;
    }

    .map_popup_content {
        font-family: $ff1;

        &_titre {
            font-family: $ff1;
            color: $col3;
            font-size: ptr(18px, $fz);
            font-weight: bold;
            text-align: left;
            margin: 0;
            padding: 0 0 0 0;
        }

        &_image {
            display: inline-block;
            vertical-align: top;
            width: 100%;
            min-height: 20vh;
            position: relative;
            overflow: hidden;

            img {
                position: absolute;
                width: 100%;
                height: auto;

                @include imgCenter;
            }
        }

        &_type {
            position: absolute;
            top: 0;
            left: 0;
            text-transform: uppercase;

            .btn {
                color: $light;
                text-transform: uppercase;
            }
        }

        &_texte {
            display: inline-block;
            vertical-align: top;
            width: 100%;
            font-size: 1rem;
            color: $col1;
            padding: 0;

            .comites {
                font-size: ptr(14px, $fz);
                color: #00A8E7;
                margin-bottom: 2rem;
            }

            .disciplines,
            .adresse,
            .contact,
            .telephone {
                @include flex(flex, row, nowrap, flex-start, flex-start);
                margin-bottom: 1rem;

                .icon {
                    flex-basis: ptr(40px, $fz);

                    > i {
                        font-size: 1.5rem;
                        color: #0078A8;
                    }
                }

                .text {
                    .label {
                        font-size: ptr(14px, $fz);
                        font-weight: bold;
                        color: $light;
                    }

                    .value {
                        font-size: ptr(14px, $fz);
                        font-weight: normal;
                        color: $light;

                        a {
                            color: $light;
                        }
                    }
                }

            }

            .btn_bar {
                color: $col3;

                &:hover {
                    color: $light;

                    &:before {
                        background-color: $light;
                    }
                }
            }

            p {
                padding: 0;
                margin: 0;
                font-size: ptr(14px, $fz);
            }
        }

        .poi {
            &-adresse {}

            &-links {
                .btn {
                    & + & {
                        margin-bottom: 0;
                    }
                }
            }

            &-horaires {
                &-titre {
                    font-weight: bold;
                }
            }
        }
    }
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
    background-color: $light;
}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* CONTROLS */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
// .leaflet {
//     &-top {
//         top: 50px !important;
//     }
// }


/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* XXS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xxs) {}

    

/* ----------------------------------------------------- */
/* XS */
/* ----------------------------------------------------- */
@media (min-width: $screen-xs) {

    .map_popup {
        padding: 1rem;
    }

}


/* ----------------------------------------------------- */
/* SM */
/* ----------------------------------------------------- */
@media (min-width: $screen-sm) {

    .leaflet-top {
        top: 0 !important;
    }

    .map_popup {
        padding: 0rem;
    }

}

    

/* ----------------------------------------------------- */
/* MD */
/* ----------------------------------------------------- */
@media (min-width: $screen-md) {

}

    

/* ----------------------------------------------------- */
/* LG */
/* ----------------------------------------------------- */
@media (min-width: $screen-lg) {}

    

/* ----------------------------------------------------- */
/* XL */
/* ----------------------------------------------------- */
@media (min-width: $screen-xl) {}

    
