.icn {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.icn-cross:before {
  content: "\ea0f";
}
.icn-alert:before {
  content: "\e900";
}
.icn-bed:before {
  content: "\e901";
}
.icn-chevron-down:before {
  content: "\e925";
}
.icn-chevron-left:before {
  content: "\e926";
}
.icn-chevron-right:before {
  content: "\e927";
}
.icn-chevron-up:before {
  content: "\e928";
}
.icn-dpe:before {
  content: "\e902";
}
.icn-envoyer:before {
  content: "\e903";
}
.icn-etage:before {
  content: "\e904";
}
.icn-filter:before {
  content: "\e905";
}
.icn-ges:before {
  content: "\e906";
}
.icn-home:before {
  content: "\e907";
}
.icn-home-full:before {
  content: "\e921";
}
.icn-k:before {
  content: "\e908";
}
.icn-k2:before {
  content: "\e924";
}
.icn-key:before {
  content: "\e909";
}
.icn-like:before {
  content: "\e90a";
}
.icn-loader:before {
  content: "\e90b";
}
.icn-localisation:before {
  content: "\e929";
}
.icn-long-arrow-down:before {
  content: "\e90c";
}
.icn-long-arrow-left:before {
  content: "\e90d";
}
.icn-long-arrow-right:before {
  content: "\e90e";
}
.icn-long-arrow-up:before {
  content: "\e90f";
}
.icn-love-house:before {
  content: "\e910";
}
.icn-money:before {
  content: "\e911";
}
.icn-money-full:before {
  content: "\e922";
}
.icn-nav-actu:before {
  content: "\e912";
}
.icn-nav-agence:before {
  content: "\e913";
}
.icn-nav-contact:before {
  content: "\e914";
}
.icn-nav-location:before {
  content: "\e915";
}
.icn-nav-location2:before {
  content: "\e923";
}
.icn-nav-transaction:before {
  content: "\e916";
}
.icn-room:before {
  content: "\e917";
}
.icn-search:before {
  content: "\e918";
}
.icn-search-fat:before {
  content: "\e92a";
}
.icn-surface:before {
  content: "\e919";
}
.icn-target:before {
  content: "\e91a";
}
.icn-team:before {
  content: "\e91b";
}
.icn-triangle-down:before {
  content: "\e91c";
}
.icn-triangle-left:before {
  content: "\e91d";
}
.icn-triangle-right:before {
  content: "\e91e";
}
.icn-triangle-up:before {
  content: "\e91f";
}
.icn-user:before {
  content: "\e920";
}
